<template>
  <TheSlider></TheSlider>
  <router-view :style="bodyColor"></router-view>
  <transition name="upd">
    <template v-if="updateExists">
      <div class="update-popup">
        Новое обновление доступно
        <br />
        <button @click="refreshApp">Обновить</button>
      </div>
    </template>
  </transition>
</template>

<script>
import update from './mixins/update.js'
import TheSlider from './components/TheSlider.vue'

import AuthBg from '@/assets/bg.svg'

export default {
  name: 'App',
  components: {
    TheSlider
  },
  data() {
    return {
      bgImage: AuthBg
    }
  },
  mixins: [update],
  created() {
    // increase all by one
    if (localStorage.getItem('azb_v') === '2') {
      localStorage.clear()
      localStorage.setItem('azb_v', '3')
      localStorage.setItem(
        'counts',
        JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
      )
    } else if (localStorage.getItem('azb_v') !== '3') {
      localStorage.clear()
      localStorage.setItem('azb_v', '3')
      localStorage.setItem(
        'counts',
        JSON.stringify([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
      )
    }
  },
  computed: {
    bodyColor() {
      //console.log(this.$route.params)
      return this.$route.params.basics || this.$route.params.nums
        ? (document.body.style.backgroundImage = `url(${this.bgImage})`)
        : (document.body.style.backgroundImage = null)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/sass/app.scss';
html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.update-popup {
  position: fixed;
  right: 1em;
  bottom: 1em;
  padding: 1em;
  border: 1px solid #3eaf7c;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0 4px 16px rgb(0 0 0 / 50%);
  text-align: center;
  z-index: 2;
}
.update-popup > button {
  margin-top: 0.5em;
  padding: 0.25em 2em;
  border-radius: 3px;
  border: 1px solid lightblue;
}
.update-popup > button:hover {
  background-color: lightgreen;
}
.update-popup > button:focus {
  outline: none;
}

.upd-leave-active {
  animation: modall 0.4s ease-in reverse;
}

@keyframes modall {
  from {
    opacity: 0;
    transform: translateY(-50px) scale(0.9);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
</style>
