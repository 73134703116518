const dateStore = {
  namespaced: true,
  state: {
    monthes: [
      'їаннуaрій',
      'феvруaрій',
      'мaртъ',
      'ґпрjллій',
      'мaій',
      'їyній',
      'їyлій',
      'ѓvгустъ',
      'септeмврій',
      'nктHврій',
      'ноeмврій',
      'декeмврій'
    ],
    days: [
      'а7',
      'в7',
      'G',
      'д7',
      'є7',
      'ѕ7',
      'з7',
      'и7',
      'f7',
      '‹',
      'а7i',
      'в7i',
      'Gi',
      'д7i',
      'є7i',
      'ѕ7i',
      'з7i',
      'и7i',
      'f7i',
      'к7',
      'к7а',
      'к7в',
      'к7г',
      'к7д',
      'к7є',
      'к7ѕ',
      'к7з',
      'к7и',
      'к7f',
      'l',
      'lа'
    ]
  },
  getters: {
    monthesArr: (state) => Object.values(state.monthes),
    daysArr: (state) => Object.values(state.days)
  }
}

export default dateStore
