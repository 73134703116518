import { createStore } from 'vuex'
import letters from './modules/letters'
import persons from './modules/persons'
import season from './modules/season'
import garazh from './modules/garazh'
import character from './modules/character'
import bigNumbers from './modules/bigNumbers'

export default createStore({
  namespaced: true,
  modules: {
    letters,
    persons,
    season,
    garazh,
    character,
    bigNumbers
  },
  state() {
    return {
      isAuth: false
    }
  },
  getters: {
    isAuth(state) {
      return state.isAuth
    }
  },
  mutations: {
    setAuth(state, payload) {
      state.isAuth = payload
    }
  }
})
