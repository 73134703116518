const characterStore = {
  namespaced: true,
  state: {
    azb: [
      ['а', 0],
      ['б', 1],
      ['в', 1],
      ['г', 1],
      ['д', 1],
      ['є', 0],
      ['ж', 1],
      ['ѕ', 1],
      ['з', 1],
      ['и', 0],
      ['і', 0],
      ['к', 1],
      ['л', 1],
      ['м', 1],
      ['н', 1],
      ['o', 0],
      ['п', 1],
      ['р', 1],
      ['с', 1],
      ['т', 1],
      ['u3', 0],
      ['ф', 1],
      ['х', 1],
      ['t', 0],
      ['ц', 1],
      ['ч', 1],
      ['ш', 1],
      ['щ', 1],
      ['ъ', 0],
      ['ы', 0],
      ['ь', 0],
      ['э', 0],
      ['ю', 0],
      ['я3', 0],
      ['w', 0],
      ['z', 0],
      ['x', 1],
      ['p', 1],
      ['f', 1],
      ['v', '']
    ]
  },
  getters: {
    azbArr: (state) => Object.values(state.azb)
  }
}

export default characterStore
