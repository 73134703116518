import { createRouter, createWebHistory } from 'vue-router'

import store from '@/store'
const TheClock = () => import('@/components/TheClock.vue')
const TheRules = () => import('@/components/TheRules.vue')
const TheAzbyka = () => import('@/components/TheAzbyka.vue')
const AzbTitla = () => import('@/components/AzbTitla.vue')
const TheUnits = () => import('@/components/TheUnits.vue')
const TheCifir = () => import('@/components/TheCifir.vue')
const TheVspan = () => import('@/components/TheVspan.vue')
import NotFound from '@/components/pages/NotFound.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: '/clock'
    },
    {
      path: '/clock',
      name: 'clock',
      component: TheClock
    },
    {
      path: '/rules',
      name: 'rules',
      component: TheRules,
      meta: { requiresAuth: true }
    },
    {
      path: '/azbyka',
      name: 'azbyka',
      component: TheAzbyka,
      meta: { requiresAuth: true }
    },
    {
      path: '/letters',
      name: 'letters',
      props: {
        switchBtns: 3
      },
      component: AzbTitla,
      meta: { requiresAuth: true }
    },
    {
      path: '/titla',
      name: 'titla',
      props: {
        switchBtns: 2
      },
      component: AzbTitla,
      meta: { requiresAuth: true }
    },
    {
      path: '/simple',
      name: 'simple',
      props: {
        switchBtns: 1,
        orderIrm: 1
      },
      component: TheUnits,
      meta: { requiresAuth: true }
    },
    {
      path: '/teens',
      name: 'teens',
      props: {
        switchBtns: 1,
        orderIrm: 11
      },
      component: TheUnits,
      meta: { requiresAuth: true }
    },
    {
      path: '/tens',
      name: 'tens',
      props: {
        switchBtns: 1,
        orderIrm: 10
      },
      component: TheUnits,
      meta: { requiresAuth: true }
    },
    {
      path: '/sotni',
      name: 'sotni',
      props: {
        switchBtns: 1,
        orderIrm: 100
      },
      component: TheUnits,
      meta: { requiresAuth: true }
    },
    {
      path: '/iskus',
      name: 'iskus',
      props: {
        switchBtns: 1,
        orderIrm: 300
      },
      component: TheUnits,
      meta: { requiresAuth: true }
    },
    {
      path: '/fifteen',
      name: 'cifir',
      component: TheCifir,
      meta: { requiresAuth: true }
    },
    {
      path: '/vspan',
      name: 'vspan',
      component: TheVspan,
      meta: { requiresAuth: true }
    },
    { path: '/:notFound(.*)', component: NotFound }
  ]
})

router.beforeEach((to, _, next) => {
  if (to.meta.requiresAuth && !store.getters.isAuth) {
    next('/clock')
  } else {
    if (to.name === 'letters') {
      to.params.basics = getNoRepInt(20, 0, 40)
    } else if (to.name === 'titla') {
      to.params.basics = getNoRepInt(20, 0, 111)
    } else if (to.name === 'simple') {
      to.params.nums = dynamicPropsFn()
    } else if (to.name === 'teens') {
      to.params.nums = dynamicPropsFn()
    } else if (to.name === 'tens') {
      to.params.nums = dynamicPropsFn()
    } else if (to.name === 'sotni') {
      to.params.nums = dynamicPropsFn()
    } else if (to.name === 'iskus') {
      to.params.nums = []
    }
    next()
  }
})

function dynamicPropsFn() {
  let arr = []
  arr = getNoRepInt(9, 0, 8)
    .concat(getNoRepInt(9, 0, 8))
    .concat(getNoRepInt(2, 0, 8))
  return arr
}

function getNoRepInt(size, min, max) {
  let arr = []
  while (arr.length < size) {
    let random = Math.floor(Math.random() * (max - min + 1)) + min
    if (!arr.includes(random)) {
      arr.push(random)
    }
  }
  return arr
}

export default router
