export default {
  namespaced: true,
  state() {
    return {
      bigNums: [
        {
          de: 'тhсzща, ─ ¤а7., ¤в7. ..',
          rya: '1000, 2000, 3000 ..',
          al: 'tissha'
        },
        {
          de: 'тьмA',
          rya: '10 000',
          al: 'tma'
        },
        { de: 'легеHнъ, или несвёдь', rya: '100 000', al: 'legeon' },
        { de: 'леHдръ', rya: '1 миллион', al: 'leodr' },
        { de: 'врaнъ', rya: '10 миллионов', al: 'vran' },
        { de: 'кол0да', rya: '100 миллионов', al: 'koloda' },
        { de: 'тьмA тeмъ', rya: '1 миллиард', al: 'tma_tem' }
      ]
    }
  },
  getters: {
    getBigNums(state) {
      return state.bigNums
    }
  }
}
