<template>
  <!-- <div> -->
  <div
    @click="goToHumb"
    class="hamburger"
    :class="isActive === true ? 'rotate' : 'arotate'"
  >
    <span></span>
    <span class="long"></span>
    <span></span>
  </div>
  <div class="menu" :class="addAct">
    <div class="menu__block">
      <div @click="goTo" class="menu__block-close">
        <svg
          width="29"
          height="30"
          viewBox="0 0 29 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.1568 14.5231L28.4489 3.23075C29.1837 2.49623 29.1837 1.30861 28.4489 0.574085C27.7144 -0.160437 26.5267 -0.160437 25.7922 0.574085L14.4998 11.8665L3.20781 0.574085C2.47295 -0.160437 1.28567 -0.160437 0.551149 0.574085C-0.183716 1.30861 -0.183716 2.49623 0.551149 3.23075L11.8432 14.5231L0.551149 25.8155C-0.183716 26.55 -0.183716 27.7376 0.551149 28.4721C0.917206 28.8385 1.39852 29.0226 1.87948 29.0226C2.36045 29.0226 2.84141 28.8385 3.20781 28.4721L14.4998 17.1798L25.7922 28.4721C26.1586 28.8385 26.6396 29.0226 27.1205 29.0226C27.6015 29.0226 28.0825 28.8385 28.4489 28.4721C29.1837 27.7376 29.1837 26.55 28.4489 25.8155L17.1568 14.5231Z"
            fill="black"
          />
        </svg>
      </div>
      <nav>
        <ul class="menu__list">
          <li class="menu__link">
            <router-link
              :style="succeed(0)"
              :to="{ name: 'rules' }"
              @click="goTo"
              >Правила</router-link
            >
          </li>
          <li class="menu__link">
            <router-link
              :style="succeed(1)"
              :to="{ name: 'azbyka' }"
              @click="goTo"
              >от Аза до Ижицы</router-link
            >
          </li>
          <li class="menu__link">
            <router-link
              :style="succeed(2)"
              :to="{ name: 'letters' }"
              @click="goTo"
              >Буквы</router-link
            >
          </li>
          <li class="menu__link">
            <router-link
              :style="succeed(3)"
              :to="{ name: 'titla' }"
              @click="goTo"
              >Слова с титлами</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :style="succeed(4)" :to="propsToUnits" @click="goTo"
              >Единицы</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :style="succeed(5)" :to="propsToTeens" @click="goTo"
              >Второй десяток</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :style="succeed(6)" :to="propsToTens" @click="goTo"
              >Десятки</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :style="succeed(7)" :to="propsToSotni" @click="goTo"
              >Сотни</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :style="succeed(8)" :to="propsToIskus" @click="goTo"
              >Для искусных</router-link
            >
          </li>
          <li class="menu__link">
            <router-link
              :style="succeed(9)"
              :to="{ name: 'cifir' }"
              @click="goTo"
              >в 15 - цифирью</router-link
            >
          </li>
          <li class="menu__link">
            <router-link
              :style="succeed(10)"
              :to="{ name: 'vspan' }"
              @click="goTo"
              >Большие числа</router-link
            >
          </li>
          <li class="menu__link">
            <router-link :to="{ name: 'clock' }" @click="goToClock"
              >Главная</router-link
            >
          </li>
        </ul>
      </nav>
      <div id="share-network-list">
        <ShareNetwork
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :style="{ backgroundColor: network.color }"
          :url="sharing.url"
          :title="sharing.title"
          :description="sharing.description"
        >
          <template v-if="network.name === 'Email'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="#fff"
                d="M0 128C0 92.65 28.65 64 64 64H448C483.3 64 512 92.65 512 128V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V128zM48 128V150.1L220.5 291.7C241.1 308.7 270.9 308.7 291.5 291.7L464 150.1V127.1C464 119.2 456.8 111.1 448 111.1H64C55.16 111.1 48 119.2 48 127.1L48 128zM48 212.2V384C48 392.8 55.16 400 64 400H448C456.8 400 464 392.8 464 384V212.2L322 328.8C283.6 360.3 228.4 360.3 189.1 328.8L48 212.2z"
              />
            </svg>
          </template>
          <template v-if="network.name === 'Odnoklassniki'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
              <path
                fill="#fff"
                d="M275.1 334c-27.4 17.4-65.1 24.3-90 26.9l20.9 20.6 76.3 76.3c27.9 28.6-17.5 73.3-45.7 45.7-19.1-19.4-47.1-47.4-76.3-76.6L84 503.4c-28.2 27.5-73.6-17.6-45.4-45.7 19.4-19.4 47.1-47.4 76.3-76.3l20.6-20.6c-24.6-2.6-62.9-9.1-90.6-26.9-32.6-21-46.9-33.3-34.3-59 7.4-14.6 27.7-26.9 54.6-5.7 0 0 36.3 28.9 94.9 28.9s94.9-28.9 94.9-28.9c26.9-21.1 47.1-8.9 54.6 5.7 12.4 25.7-1.9 38-34.5 59.1zM30.3 129.7C30.3 58 88.6 0 160 0s129.7 58 129.7 129.7c0 71.4-58.3 129.4-129.7 129.4s-129.7-58-129.7-129.4zm66 0c0 35.1 28.6 63.7 63.7 63.7s63.7-28.6 63.7-63.7c0-35.4-28.6-64-63.7-64s-63.7 28.6-63.7 64z"
              />
            </svg>
          </template>
          <template v-if="network.name === 'Telegram'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
              <path
                fill="#fff"
                d="M248,8C111.033,8,0,119.033,0,256S111.033,504,248,504,496,392.967,496,256,384.967,8,248,8ZM362.952,176.66c-3.732,39.215-19.881,134.378-28.1,178.3-3.476,18.584-10.322,24.816-16.948,25.425-14.4,1.326-25.338-9.517-39.287-18.661-21.827-14.308-34.158-23.215-55.346-37.177-24.485-16.135-8.612-25,5.342-39.5,3.652-3.793,67.107-61.51,68.335-66.746.153-.655.3-3.1-1.154-4.384s-3.59-.849-5.135-.5q-3.283.746-104.608,69.142-14.845,10.194-26.894,9.934c-8.855-.191-25.888-5.006-38.551-9.123-15.531-5.048-27.875-7.717-26.8-16.291q.84-6.7,18.45-13.7,108.446-47.248,144.628-62.3c68.872-28.647,83.183-33.623,92.511-33.789,2.052-.034,6.639.474,9.61,2.885a10.452,10.452,0,0,1,3.53,6.716A43.765,43.765,0,0,1,362.952,176.66Z"
              />
            </svg>
          </template>
          <template v-if="network.name === 'Viber'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path
                fill="#fff"
                d="M444 49.9C431.3 38.2 379.9.9 265.3.4c0 0-135.1-8.1-200.9 52.3C27.8 89.3 14.9 143 13.5 209.5c-1.4 66.5-3.1 191.1 117 224.9h.1l-.1 51.6s-.8 20.9 13 25.1c16.6 5.2 26.4-10.7 42.3-27.8 8.7-9.4 20.7-23.2 29.8-33.7 82.2 6.9 145.3-8.9 152.5-11.2 16.6-5.4 110.5-17.4 125.7-142 15.8-128.6-7.6-209.8-49.8-246.5zM457.9 287c-12.9 104-89 110.6-103 115.1-6 1.9-61.5 15.7-131.2 11.2 0 0-52 62.7-68.2 79-5.3 5.3-11.1 4.8-11-5.7 0-6.9.4-85.7.4-85.7-.1 0-.1 0 0 0-101.8-28.2-95.8-134.3-94.7-189.8 1.1-55.5 11.6-101 42.6-131.6 55.7-50.5 170.4-43 170.4-43 96.9.4 143.3 29.6 154.1 39.4 35.7 30.6 53.9 103.8 40.6 211.1zm-139-80.8c.4 8.6-12.5 9.2-12.9.6-1.1-22-11.4-32.7-32.6-33.9-8.6-.5-7.8-13.4.7-12.9 27.9 1.5 43.4 17.5 44.8 46.2zm20.3 11.3c1-42.4-25.5-75.6-75.8-79.3-8.5-.6-7.6-13.5.9-12.9 58 4.2 88.9 44.1 87.8 92.5-.1 8.6-13.1 8.2-12.9-.3zm47 13.4c.1 8.6-12.9 8.7-12.9.1-.6-81.5-54.9-125.9-120.8-126.4-8.5-.1-8.5-12.9 0-12.9 73.7.5 133 51.4 133.7 139.2zM374.9 329v.2c-10.8 19-31 40-51.8 33.3l-.2-.3c-21.1-5.9-70.8-31.5-102.2-56.5-16.2-12.8-31-27.9-42.4-42.4-10.3-12.9-20.7-28.2-30.8-46.6-21.3-38.5-26-55.7-26-55.7-6.7-20.8 14.2-41 33.3-51.8h.2c9.2-4.8 18-3.2 23.9 3.9 0 0 12.4 14.8 17.7 22.1 5 6.8 11.7 17.7 15.2 23.8 6.1 10.9 2.3 22-3.7 26.6l-12 9.6c-6.1 4.9-5.3 14-5.3 14s17.8 67.3 84.3 84.3c0 0 9.1.8 14-5.3l9.6-12c4.6-6 15.7-9.8 26.6-3.7 14.7 8.3 33.4 21.2 45.8 32.9 7 5.7 8.6 14.4 3.8 23.6z"
              />
            </svg>
          </template>
          <template v-if="network.name === 'Vk'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#fff"
                d="M31.4907 63.4907C0 94.9813 0 145.671 0 247.04V264.96C0 366.329 0 417.019 31.4907 448.509C62.9813 480 113.671 480 215.04 480H232.96C334.329 480 385.019 480 416.509 448.509C448 417.019 448 366.329 448 264.96V247.04C448 145.671 448 94.9813 416.509 63.4907C385.019 32 334.329 32 232.96 32H215.04C113.671 32 62.9813 32 31.4907 63.4907ZM75.6 168.267H126.747C128.427 253.76 166.133 289.973 196 297.44V168.267H244.16V242C273.653 238.827 304.64 205.227 315.093 168.267H363.253C359.313 187.435 351.46 205.583 340.186 221.579C328.913 237.574 314.461 251.071 297.733 261.227C316.41 270.499 332.907 283.63 346.132 299.751C359.357 315.873 369.01 334.618 374.453 354.747H321.44C316.555 337.262 306.614 321.61 292.865 309.754C279.117 297.899 262.173 290.368 244.16 288.107V354.747H238.373C136.267 354.747 78.0267 284.747 75.6 168.267Z"
              />
            </svg>
          </template>
          <template v-if="network.name === 'Whatsapp'">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                fill="#fff"
                d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"
              />
            </svg>
          </template>
        </ShareNetwork>
      </div>
      <div class="menu__block-contacts">
        <a
          class="contact"
          href="http://s-prihod.cerkov.ru/zanyatiya/naglyadnye-posobiya/"
          target="blank"
          >Наглядные пособия</a
        >
        <div class="contact">
          <a href="mailto:istuunir@gmail.com">Написать нам</a>
        </div>
      </div>
    </div>
    <div @click="goTo" class="menu__overlay"></div>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: 'TheSlider',
  data() {
    return {
      isActive: false,
      cnt: [],
      sharing: {
        url: 'https://dobroazbyka.ru',
        title: 'Азбука с цифирью - читаем по-славянски',
        description:
          'Web-приложение для изучения славянской азбуки и цифири в виде комплекса ментальных упражнений на память и внимание.'
      },
      networks: [
        {
          network: 'email',
          name: 'Email',
          color: '#333333'
        },
        {
          network: 'odnoklassniki',
          name: 'Odnoklassniki',
          color: '#ed812b'
        },
        {
          network: 'telegram',
          name: 'Telegram',
          color: '#0088cc'
        },
        {
          network: 'viber',
          name: 'Viber',
          color: '#59267c'
        },
        {
          network: 'vk',
          name: 'Vk',
          color: '#4a76a8'
        },
        {
          network: 'whatsapp',
          name: 'Whatsapp',
          color: '#25d366'
        }
      ]
    }
  },
  computed: {
    addAct() {
      return this.isActive === true ? 'active' : ''
    },
    propsToUnits() {
      return {
        name: 'simple'
      }
    },
    propsToTeens() {
      return {
        name: 'teens'
      }
    },
    propsToTens() {
      return {
        name: 'tens'
      }
    },
    propsToSotni() {
      return {
        name: 'sotni'
      }
    },
    propsToIskus() {
      return {
        name: 'iskus'
      }
    }
  },
  methods: {
    succeed(i) {
      return this.cnt[i] ? 'color: black; font-weight: bold' : ''
    },
    goToHumb() {
      this.cnt = JSON.parse(localStorage.getItem('counts'))
      this.goTo()
    },
    goTo() {
      this.$store.commit('setAuth', true)
      this.isActive = !this.isActive
    },
    goToClock() {
      this.isActive = !this.isActive
    }
  }
}
</script>

<style lang="scss" scoped>
.hamburger {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  cursor: pointer;
  z-index: 9;
  span {
    display: block;
    width: 3rem;
    height: 0.25rem;
    background: #151812;
  }
  span.long {
    width: 3rem;
    height: 0.25rem;
  }
}

.rotate {
  transition: 0.7s all ease;
  transform: rotate(180deg);
}
.arotate {
  transition: 0.7s all ease;
  transform: rotate(0deg);
}

.menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
  &.active {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  &__block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 20rem;
    background-color: #ffa501;
    height: 100%;
    overflow-y: auto;
    padding-top: 4.5rem;
    padding-left: 2.5rem;
    transition: all 0.6s;
    z-index: 10;
    &-close {
      position: absolute;
      top: 1.6rem;
      right: 1.6rem;
      cursor: pointer;
    }
    &-contacts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-evenly;
      margin-bottom: 1.5rem;
      margin-top: 1rem;
    }
    #share-network-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      max-width: 100%;
      a[class^='share-network-'] {
        flex: none;
        border-radius: 3px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-content: center;
        align-items: center;
        cursor: pointer;
        margin: 5px;
      }
      a[class^='share-network-'] svg {
        margin: 0.5rem;
        flex: 0 1 auto;
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  &__list {
    list-style-type: none;
    padding-left: 2.1rem;
  }

  &__link {
    position: relative;
    font-size: 1.5rem;
    line-height: 2.2rem;
    margin-bottom: 0.9rem;
    text-align: start;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: -2.1rem;
      top: 0.8rem;
      width: 0.7rem;
      height: 0.7rem;
      border-radius: 50%;
      background-color: #795548;
      transition: all 0.5s;
    }
    &:hover {
      text-decoration: underline;
      &:before {
        background-color: #fff;
      }
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.5;
  }
}

a {
  color: black;
}

.contact {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 2.2rem;
  color: darkblue;
  text-align: left;
  // margin-left: -3rem;
  &:hover {
    text-decoration: underline;
  }
}

// field smartphone
@media (max-width: 768px) {
  .hamburger {
    top: 1.3rem;
    right: 1.2rem;
    height: 1.6rem;
    span {
      width: 2.6rem;
      height: 0.25rem;
    }
    span.long {
      width: 2.6rem;
      height: 0.25rem;
    }
  }
}
@media screen and (max-width: 576px) {
  .menu {
    &__block {
      padding-top: 4rem;
      padding-left: 2rem;
    }
    &__list {
      list-style-type: none;
    }
    &__link {
      font-size: 1.5rem;
      line-height: 2.1rem;
    }
    .contact {
      line-height: 1.7rem;
      color: black;
    }
  }
}
</style>
