import { createApp } from 'vue'
import App from './App.vue'
import VueSocialSharing from 'vue-social-sharing'
import './registerServiceWorker'
import router from './router'
import store from './store'

const app = createApp(App)
app.use(VueSocialSharing)
app.use(store).use(router).mount('#app')
