const garazhStore = {
  namespaced: true,
  state: {
    letterss: [
      ['А а', '0'],
      ['Б б', '1'],
      ['В в', '1'],
      ['Г г', '1'],
      ['Д д', '1'],
      ['Е є, Е е', '0'],
      ['Ж ж', '1'],
      ['Ѕ ѕ', '1'],
      ['З з', '1'],
      ['И и', '0'],
      ['I і', '0'],
      ['К к', '1'],
      ['Л л', '1'],
      ['М м', '1'],
      ['Н н', '1'],
      ['O o,  О о', '0'],
      ['П п', '1'],
      ['Р р', '1'],
      ['С с', '1'],
      ['Т т', '1'],
      ['U u3, У у', '0'],
      ['Ф ф', '1'],
      ['Х х', '1'],
      ['T t', '0'],
      ['Ц ц', '1'],
      ['Ч ч', '1'],
      ['Ш ш', '1'],
      ['Щ щ', '1'],
      ['Ъ ъ', '0'],
      ['Ы ы', '0'],
      ['Ь ь', '0'],
      ['Э э', '0'],
      ['Ю ю', '0'],
      ['Я я3', '0'],
      ['W w, Q q', '0'],
      ['Z z', '0'],
      ['X x', '1'],
      ['P p', '1'],
      ['F f', '1'],
      ['V v', '1']
    ],
    nameSndArr: [
      ['а4зъ', '[а]'],
      ['бyки', '[б]'],
      ['вёди', '[в]'],
      ['глаг0ль', '[г]'],
      ['добро2', '[д]'],
      ['є4сть', '[е]'],
      ['живёте', '[ж]'],
      ['ѕэлw2', '[з]'],
      ['землS', '[з]'],
      ['и4же', '[и]'],
      ['и5', '[и]'],
      ['кaкw', '[к]'],
      ['лю1ди', '[л]'],
      ['мыслёте', '[м]'],
      ['нaшъ', '[н]'],
      ['o4нъ', '[о]'],
      ['пок0й', '[п]'],
      ['рцы2', '[р]'],
      ['сл0во', '[с]'],
      ['твeрдо', '[т]'],
      ['u4къ', '[у]'],
      ['фeртъ', '[ф]'],
      ['хёръ', '[х]'],
      ['w4тъ', '[от]'],
      ['цы2', '[ц]'],
      ['чeрвь', '[ч]'],
      ['шA', '[ш]'],
      ['щA', '[щ]'],
      ['є4ръ', ''],
      ['є3ры2', '[ы]'],
      ['є4рь', ''],
      ['я4ть', '[е]'],
      ['ю5', '[ю]'],
      ['я5', '[я]'],
      ['w3мeга', '[о]'],
      ['ю4съ мaлый', '[я]'],
      ['кси2', '[кс]'],
      ['пси2', '[пс]'],
      ['fитA', '[ф]'],
      ['и4жица', '[в]']
    ],
    tsifirArr: [
      'а7. - є3ди1нъ',
      '–',
      'в7. – два2',
      'G. – три2',
      'д7. – четы1ре',
      'є7. – пsть',
      '–',
      'ѕ7. – ше1сть',
      'з7. – се1дмь',
      'и7. – o4смь',
      '‹. – де1сzть',
      'к7. – два1десzть',
      'l. – три1десzть',
      'м7. – четы1редесzть',
      'н7. – пzтьдесz1тъ',
      'o7. – се1дмьдесzтъ',
      'п7. – o4смьдесzтъ',
      'R. – сто2',
      '©. – двёстэ',
      'т7. – три1ста',
      'µ7. – четы1реста',
      'ф7. – пzтьсw1тъ',
      'х7. – шестьсw1тъ',
      't. – o3смьсw1тъ',
      'ц7. – девzтьсw1тъ',
      'ч7. – де1вzтьдесzтъ',
      '–',
      '–',
      '–',
      '–',
      '–',
      '–',
      '–',
      '–',
      '–',
      '–',
      '…. – шестьдесsтъ',
      'p7. – седмьсw1тъ',
      'f7. – де1вzть',
      '–',
      '–'
    ],
    sentencesObj: {
      0: [
        'Вёровахъ, тёмже возглаг0лахъ: ѓзъ же смири1хсz ѕэлw2. ',
        '(Пс. 115:1)',
        'ВLко, прости2 беззакHніz на6ша: ст7hй, посэти2 и3 и3сцэли2 нeмwщи нaшz, и4мене твоегw2 рaди. ',
        '(из молитвы)'
      ],
      1: [
        'Бyдите u4бо милосeрди, я4коже и3 nц7ъ вaшъ млcрдъ є4сть. ',
        '(Лк. 7:36)'
      ],
      2: [
        'Пробaви млcть твою2 вёдущымъ тS и3 прaвду твою2 пр†вымъ сeрдцемъ. ',
        '(Пс. 35:11)'
      ],
      3: [
        'Нн7э tпущaеши рабA твоего2, вLко, по гл7г0лу твоемY, съ ми1ромъ. ',
        '(Лк. 2:27)'
      ],
      4: [
        'СE, что2 добро2, и3ли2 что2 красно2, но є4же жи1ти брaтіи вкyпэ. ',
        '(Пс. 132:1)'
      ],
      5: [
        'душA б0льши є4сть пи1щи, и3 тёло nдeжды. ',
        '(Лк. 12:23)',
        'Блажeни слhшащіи сл0во б9іе и3 хранsщіи є5. ',
        '(Лк. 11:28)'
      ],
      6: [
        'ГDнz землS, и3 и3сполнeніе є3S, вселeннаz и3 вси2 живyщіи на нeй. ',
        '(Пс. 23:1)'
      ],
      7: [
        'Ты2 заповёдалъ є3си2 зaпwвэди тво‰ сохрани1ти ѕэлw2. ',
        '(Пс. 118:4)'
      ],
      8: [
        'да пріи1детъ цrтвіе твоE: да бyдетъ в0лz твоS, ћкw на нб7си2, и3 на земли2. ',
        '(Мф. 6:10)'
      ],
      9: ['.. И$же бо мeншій є4сть въ вaсъ, сeй є4сть вели1къ. ', '(Лк. 9:48)'],
      10: [
        '.. Вёруй въ гDа їи7са хrтA, и3 сп7сeшисz ты2 и3 вeсь д0мъ тв0й. ',
        '(Деян. 16:31)',
        'Сeй ни1щій воззвA, и3 гдcь u3слhша и5, и3 t всёхъ скорбeй є3гw2 сп7сE и5. ',
        '(Пс. 33:7)'
      ],
      11: [
        '.. кaкw ќбw могY разумёти, ѓще не кто2 настaвитъ мS; ',
        '(Деян. 8:31)'
      ],
      12: [
        'Сп7си2, гдcи, лю1ди твоz6, и3 бlгослови2 достоsніе твое2, ',
        '(из тропаря Кресту)'
      ],
      13: [
        'Мудровaніе бо плотск0е смeрть є4сть: а3 мудровaніе дух0вное жив0тъ и3 ми1ръ. ',
        '(Рим. 8:6)'
      ],
      14: ['Хлёбъ нaшъ насyщный дaждь нaмъ днeсь ', '(Мф. 6:11)'],
      15: ['џнъ же прошeдъ посредЁ и4хъ, и3дsше ', '(Лк. 4:30)'],
      16: ['Њбрати1сz, душE моS, въ пок0й тв0й ', '(Пс. 114:6)'],
      17: [
        'И# ск0рw шeдше рцhте ўч7нкHмъ є3гw2, ћкw востA t мeртвыхъ ',
        '(Мф. 28:7)'
      ],
      18: [
        'Въ начaлэ бЁ сл0во, и3 сл0во бЁ ў бGа, и3 бGъ бЁ сл0во. ',
        '(Ин. 1:1)'
      ],
      19: [
        'Воздaждь ми2 рaдость сп7сeніz твоегw2, и3 д¦омъ вLчнимъ ўтверди2 мS. ',
        '(Пс. 50:14)'
      ],
      20: [
        'И$стину стzжи2 и3 не tри1ни мyдрости и3 u3чeніz и3 рaзума ',
        '(Притч. 23:23)'
      ],
      21: [
        'Првdникъ ћкw фjніxъ процвэтeтъ: ћкw кeдръ, и4же въ лівaнэ, ўмн0житсz. ',
        '(Пс. 91:13)'
      ],
      22: ['Хвали1те и4мz гDне, хвали1те, раби2 гDа ', '(Пс. 134:1)'],
      23: [
        'Tврати2 лицE твоE t грBхъ мои1хъ, и3 всz2 беззакHніz моz2 њчи1сти. ',
        '(Пс. 50:11)'
      ],
      24: ['Ю$ношы тaкожде моли2 цэломyдрствовати ', '(Тит. 2:6)'],
      25: [
        'Ѓзъ же є4смь чeрвь, ґ не человёкъ, поношeніе человёкwвъ и3 ўничижeніе людjй. ',
        '(Пс. 21:7)'
      ],
      26: [
        'ДушE моS, душE моS, востaни, востaни, что2 спи1ши; ',
        '(из Покаянного канона)'
      ],
      27: [
        'Щeдръ и3 млcтивъ гDь, долготерпэли1въ и3 многомлcтивъ. ',
        '(Пс. 102:8)'
      ],
      28: [
        'w3бъsтіе, подъsти, пaерокъ, рaзумъ, свётъ ',
        '(как правило, пишется в конце слова после согласной)'
      ],
      29: [
        'а4лчущыz и3сп0лни бла6гъ, и3 богатsщыzсz tпусти2 тщы2. ',
        '(Лк. 1:53)'
      ],
      30: [
        'в0змутъ, тмA, предстaтелство ',
        '(возьмут, тьма, предстательство; в конце слова показывает мягкость согласного)'
      ],
      31: ['вёра без8 дёлъ мертвA є4сть ', '(Иак. 2:6)'],
      32: [
        '.. Мhсль благaz вёдzщымъ ю5 приближaетсz, мyжъ же мyдръ w3брsщетъ ю5 .. ',
        '(Притч. 19:7)'
      ],
      33: [
        'я3дhй не я3дyщагw да не u3корsетъ: и3 не я3дhй я3дyщагw да не w3суждaетъ: бGъ бо є3го2 пріsтъ ',
        '(Рим. 14:3)'
      ],
      34: [
        'Ѓзъ є4смь ѓлфа и3 њмeга, начaтокъ и3 конeцъ, глаго1летъ гDь, сhй, и3 и4же бЁ, и3 грzдhй, вседержи1тель. ',
        '(Апок. 1:8)',
        'Qле стрaшнагw тaинства! ',
        '(из Последования ко Св. Причащению)'
      ],
      35: [
        'Ўдержи2 љзhкъ тв0й t ѕлA и3 ўстнЁ твои2, є4же не глаг0лати льсти2. ',
        '(Пс. 33:14)',
        'МнHги скHрби првdнымъ, и3 t всёхъ и4хъ и3збaвитъ | гDь ',
        '(Пс. 33:20)'
      ],
      36: [
        'ст7az бlжeннаz мт7и xeніе, моли2 бGа w3 нaсъ! ',
        '(из акафиста блж. Ксении)'
      ],
      37: [
        'И#сповёдайтесz гDеви въ гyслехъ, во pалти1ри десzтострyннэмъ п0йте є3мY: ',
        '(Пс. 32:2)'
      ],
      38: ['FавHръ и3 є3рмHнъ w3 и4мени твоeмъ возрaдуетасz ', '(Пс. 88:13)'],
      39: [
        'ћкоже бо бhсть їHна знaменіе нінеvjтwмъ, тaкw бyдетъ и3 сн7ъ чlвёческій р0ду семY. ',
        '(Лк. 11:30)',
        'Бsху же нёцыи негодyюще въ себЁ и3 глаг0люще: почто2 ги1бель сіS мv1рнаz бhсть; ',
        '(Мр. 14:4)'
      ]
    },
    descriptionsObj: {
      gdDescr:
        "Титло над буквой является тем же самым, что нимб над головой на иконе: показывает Божественную святость и тайну. Само слово 'титло' означает знак отличия, звание (титул). Для каждого вида титла существует свой устоявшийся набор слов",
      osDescr:
        "Слова со знаками титла произносятся обычным способом, не следует 'глотать' звуки, буквы для которых не прописаны",
      rch1: "Первое титло используется только в одном слове, читаемом как 'имя рек' (т.е. должно назвать чье-то имя - свое или другого).",
      rch2: 'Второе титло используется в слове «зачало». ЗАЧАЛО — часть текста из Евангелия или Апостола, читаемого за Богослужением:',
      tp1: 'Простое титло наиболее распространено, и в том числе используется в цифири. Титла видим в храме на распятии, на табличке над головой Господа Иисуса Христа:',
      tp2: 'ерóк (пáерок, ерик) встречается вместо ъ (ера) в предлогах и приставках (кроме предлогов «к», «в», «с» и «от»):',
      vk1: 'Данные знаки не определяют особенности ударений',
      vk2: 'камóра (облеченное) ставится над ударной гласной в формах множественного и двойственного числа.',
      vk3: [
        'Написа2 же и3 т‡тла піла1тъ, и3 положи2 на крестЁ: бё же написа1но: їи7съ назwрzни1нъ цRь їуде1йскій.',
        ' (Ин. 19:19)'
      ],
      zk1: 'Звáтельце (придыхание) - знак над гласной буквой, стоящей в начале слова.'
    }
  },
  getters: {
    letterArr: (state) => Object.values(state.letterss),
    nameSnd: (state) => Object.values(state.nameSndArr),
    tsifir: (state) => Object.values(state.tsifirArr),
    sentences: (state) => Object.values(state.sentencesObj),
    descriptions: (state) => Object.values(state.descriptionsObj)
  }
}

export default garazhStore
