const personsStore = {
  namespaced: true,
  state: {
    persons: [
      { id: 1, name: 'Аввакум', sname: 'Ґввакyмъ' },
      { id: 2, name: 'Авдакт', sname: 'Ґвдaктъ' },
      { id: 3, name: 'Авдий', sname: 'Ґвдjй' },
      { id: 4, name: 'Авдон', sname: 'Ґвд0нъ' },
      { id: 5, name: 'Авенир', sname: 'Ґвени1ръ' },
      { id: 6, name: 'Аверкий', sname: 'Ґвeркій' },
      { id: 7, name: 'Авив', sname: 'Ґвjвъ' },
      { id: 8, name: 'Авид', sname: 'Ґвjдъ' },
      { id: 9, name: 'Авим', sname: 'Ґвjмъ' },
      { id: 10, name: 'Авраамий', sname: 'Ґвраaмій' },
      { id: 11, name: 'Авраам', sname: 'Ґвраaмъ' },
      { id: 12, name: 'Абрам', sname: 'Ґвраaмъ' },
      { id: 13, name: 'Аврамий', sname: 'Ґврaмій' },
      { id: 14, name: 'Авудим', sname: 'Ґвудjмъ' },
      { id: 15, name: 'Авундий', sname: 'Ґвyндій' },
      { id: 16, name: 'Авип', sname: 'ҐвЂпъ' },
      { id: 17, name: 'Агавва', sname: 'Ґгaвва' },
      { id: 18, name: 'Агав', sname: 'Ґгaвъ' },
      { id: 19, name: 'Агапит', sname: 'Ґгaпитъ' },
      { id: 20, name: 'Агапий', sname: 'Ґгaпій' },
      { id: 21, name: 'Агафангел', sname: 'Ґгаfаггeлъ' },
      { id: 22, name: 'Агафодор', sname: 'ҐгаfодHръ' },
      { id: 23, name: 'Агафоник', sname: 'Ґгаfонjкъ' },
      { id: 24, name: 'Агафопод', sname: 'Ґгаfоп0дъ' },
      { id: 25, name: 'Агафопус', sname: 'Ґгаf0пусъ' },
      { id: 26, name: 'Агафон', sname: 'ҐгаfHнъ' },
      { id: 27, name: 'Аггей', sname: 'Ґггeй' },
      { id: 28, name: 'Аггий', sname: 'Ґггjй' },
      { id: 29, name: 'Аглай', sname: 'Ґглaій' },
      { id: 30, name: 'Агн', sname: 'Ѓгнъ' },
      { id: 31, name: 'Адам', sname: 'Ґдaмъ' },
      { id: 32, name: 'Адриан', sname: 'Ґдріaнъ' },
      { id: 33, name: 'Аетий', sname: 'Ґeтій' },
      { id: 34, name: 'Аза', sname: 'Ѓза' },
      { id: 35, name: 'Азадан', sname: 'Ґзадaнъ' },
      { id: 36, name: 'Азарий', sname: 'Ґзaрій' },
      { id: 37, name: 'Азат', sname: 'Ґзaтъ' },
      { id: 38, name: 'Аифал', sname: 'Ґіfaлъ' },
      { id: 39, name: 'Акакий', sname: 'Ґкaкій' },
      { id: 40, name: 'Акепсий', sname: 'Ґкеpjй' },
      { id: 41, name: 'Акепсима', sname: 'Ґкеpjма' },
      { id: 42, name: 'Акепсим', sname: 'Ґкеpjмъ' },
      { id: 43, name: 'Акиндин', sname: 'ҐкіндЂнъ' },
      { id: 44, name: ' Акутион', sname: 'Ґкутjwнъ' },
      { id: 45, name: 'Акила', sname: 'ҐкЂла' },
      { id: 46, name: 'Альвиан', sname: 'Ґлвіaнъ' },
      { id: 47, name: 'Александр', sname: 'Ґлеxaндръ' },
      { id: 48, name: 'Алексей', sname: 'Ґлеxjй' },
      { id: 49, name: 'Алим', sname: 'Ґлjмъ' },
      { id: 50, name: 'Алфей', sname: 'Ґлфeй' },
      { id: 51, name: 'Алфий', sname: 'Ґлфjй' },
      { id: 52, name: 'Алипий', sname: 'ҐлЂпій' },
      { id: 53, name: 'Амвросий', sname: 'Ґмвр0сій' },
      { id: 54, name: 'Аммун', sname: 'Ґммyнъ' },
      { id: 55, name: 'Амон', sname: 'ҐммHнъ' },
      { id: 56, name: 'Амонит', sname: 'Ґмони1тъ' },
      { id: 57, name: 'Амплий', sname: 'Ґмплjй' },
      { id: 58, name: 'Амфиан', sname: 'Ґмфіaнъ' },
      { id: 59, name: 'Амфилохий', sname: 'Ґмфіл0хій' },
      { id: 60, name: 'Амос', sname: 'ҐмHсъ' },
      { id: 61, name: 'Анания', sname: 'Ґнaніа' },
      { id: 62, name: 'Анастасий', sname: 'Ґнастaсій' },
      { id: 63, name: 'Анатолий', sname: 'Ґнат0лій' },
      { id: 64, name: 'Андрей', sname: 'Ґндрeй' },
      { id: 65, name: 'Андроник', sname: 'Ґндронjкъ' },
      { id: 66, name: 'Анект', sname: 'Ґнeктъ' },
      { id: 67, name: 'Анемподист', sname: 'Ґнемподjстъ' },
      { id: 68, name: 'Аникита', sname: 'Ґніки1та' },
      { id: 69, name: 'Анин', sname: 'Ґнjнъ' },
      { id: 70, name: 'Антиох', sname: 'Ґнті0хъ' },
      { id: 71, name: 'Антипатр', sname: 'Ґнтіпaтръ' },
      { id: 72, name: 'Антоний', sname: 'ҐнтHній' },
      { id: 73, name: 'Антонин', sname: 'Ґнтwнjнъ' },
      { id: 74, name: 'Антипа', sname: 'ҐнтЂпа' },
      { id: 75, name: 'Анувий', sname: 'Ґнyвій' },
      { id: 76, name: 'Анфим', sname: 'Ґнfjмъ' },
      { id: 77, name: 'Анфир', sname: 'ҐнfЂръ' },
      { id: 78, name: 'Апеллий', sname: 'Ґпeллій' },
      { id: 79, name: 'Аполлинарий', sname: 'Ґполлінaрій' },
      { id: 80, name: 'Аполлоний', sname: 'ҐполлHній' },
      { id: 81, name: 'Аполлон', sname: 'ҐполлHнъ' },
      { id: 82, name: 'Аполлос', sname: 'ҐполлHсъ' },
      { id: 83, name: 'Апрониан', sname: 'Ґпроніaнъ' },
      { id: 84, name: 'Ардалион', sname: 'ҐрдаліHнъ' },
      { id: 85, name: 'Арефа', sname: 'Ґрefа' },
      { id: 86, name: 'Арис', sname: 'Ґри1съ' },
      { id: 87, name: 'Ариан', sname: 'Ґріaнъ' },
      { id: 88, name: 'Арий', sname: 'Ѓрій' },
      { id: 89, name: 'Аристарх', sname: 'Ґрістaрхъ' },
      { id: 90, name: 'Аристион', sname: 'ҐрістіHнъ' },
      { id: 91, name: 'Аристовул', sname: 'Ґрістовyлъ' },
      { id: 92, name: 'Аристоклий', sname: 'Ґрістоклjй' },
      { id: 93, name: 'Аркадий', sname: 'Ґркaдій' },
      { id: 94, name: 'Арпила', sname: 'ҐрпЂла' },
      { id: 95, name: 'Арсений', sname: 'Ґрсeній' },
      { id: 96, name: 'Артема', sname: 'Ґртeма' },
      { id: 97, name: 'Артемий', sname: 'Ґртeмій' },
      { id: 98, name: 'Артемон', sname: 'Ґртeмwнъ' },
      { id: 99, name: 'Архилий', sname: 'Ґрхілjй' },
      { id: 100, name: 'Архипп', sname: 'Ґрхjппъ' },
      { id: 101, name: 'Аскалон', sname: 'ҐскалHнъ' },
      { id: 102, name: 'Асклипиодот', sname: 'Ґскліпіод0тъ' },
      { id: 103, name: 'Астерий', sname: 'Ґстeрій' },
      { id: 104, name: 'Астий', sname: 'Ґстjй' },
      { id: 105, name: 'Астион', sname: 'ҐстіHнъ' },
      { id: 106, name: 'Асинкрит', sname: 'Ґсmгкрjтъ' },
      { id: 107, name: 'Аттий', sname: 'Ѓттій' },
      { id: 108, name: 'Африкан', sname: 'Ґфрікaнъ' },
      { id: 109, name: 'Аффоний', sname: 'Ґфf0ній' },
      { id: 110, name: 'Ахаз', sname: 'Ґхaзъ' },
      { id: 111, name: 'Ахаик', sname: 'Ґхaікъ' },
      { id: 112, name: 'Ахия', sname: 'Ґхjа' },
      { id: 113, name: 'Ахиллий', sname: 'Ґхjллій' },
      { id: 114, name: 'Афанасий', sname: 'Ґfанaсій' },
      { id: 115, name: 'Афиноген', sname: 'Ґfиногeнъ' },
      { id: 116, name: 'Афинодор', sname: 'ҐfинодHръ' },
      { id: 117, name: 'Авда', sname: 'Ѓvда' },
      { id: 118, name: 'Авделай', sname: 'Ґvделaй' },
      { id: 119, name: 'Авдифакс', sname: 'Ґvдифaxъ' },
      { id: 120, name: 'Авдиес', sname: 'Ґvдіeсъ' },
      { id: 121, name: 'Авдикий', sname: 'Ґvдjкій' },
      { id: 122, name: 'Авкт', sname: 'Ѓvктъ' },
      { id: 123, name: 'Автоном', sname: 'Ґvтон0мъ' },
      { id: 124, name: 'Авксентий', sname: 'Ґvxeнтій' },
      { id: 125, name: 'Борис', sname: 'Бори1съ' },
      { id: 126, name: 'Вавила', sname: 'ВавЂла' },
      { id: 127, name: 'Вадим', sname: 'Вадjмъ' },
      { id: 128, name: 'Вакх', sname: 'Вaкхъ' },
      { id: 129, name: 'Валентин', sname: 'Валентjнъ' },
      { id: 130, name: 'Валент', sname: 'Вaлентъ' },
      { id: 131, name: 'Валериан', sname: 'Валеріaнъ' },
      { id: 132, name: 'Валерий', sname: 'Валeрій' },
      { id: 133, name: 'Ваптос', sname: 'Вaптосъ' },
      { id: 134, name: 'Варадат', sname: 'Варадaтъ' },
      { id: 135, name: 'Варахисий', sname: 'Варахи1сій' },
      { id: 136, name: 'Варахий', sname: 'Варaхій' },
      { id: 137, name: 'Варвар', sname: 'Вaрваръ' },
      { id: 138, name: 'Варлаам', sname: 'Варлаaмъ' },
      { id: 139, name: 'Варнава', sname: 'Варнaва' },
      { id: 140, name: 'Варсава', sname: 'Варсaва' },
      { id: 141, name: 'Варсис', sname: 'Варсjсъ' },
      { id: 142, name: 'Варсонофий', sname: 'Варсон0фій' },
      { id: 143, name: 'Варул', sname: 'Варyлъ' },
      { id: 144, name: 'Варух', sname: 'Варyхъ' },
      { id: 145, name: 'Варфоломей', sname: 'Варfоломeй' },
      { id: 146, name: 'Варипсав', sname: 'Варmpaвъ' },
      { id: 147, name: 'Василид', sname: 'Васілjдъ' },
      { id: 148, name: 'Василий', sname: 'Васjлій' },
      { id: 149, name: 'Василиск', sname: 'Васілjскъ' },
      { id: 150, name: 'Вассиан', sname: 'Вассіaнъ' },
      { id: 151, name: 'Васс', sname: 'Вaссъ' },
      { id: 152, name: 'Васой', sname: 'ВасHй' },
      { id: 153, name: 'Вата', sname: 'Вaта' },
      { id: 154, name: 'Вахтисий', sname: 'Вахтjсій' },
      { id: 155, name: 'Вафусий', sname: 'Ваfyсій' },
      { id: 156, name: 'Вендимиан', sname: 'Вендимjанъ' },
      { id: 157, name: 'Венедим', sname: 'Венеди1мъ' },
      { id: 158, name: 'Венедикт', sname: 'Венедjктъ' },
      { id: 159, name: 'Вениамин', sname: 'Веніами1нъ' },
      { id: 160, name: 'Вил', sname: 'Ви1лъ' },
      { id: 161, name: 'Вирилад', sname: 'Вирілaдъ' },
      { id: 162, name: 'Виссарион', sname: 'ВиссаріHнъ' },
      { id: 163, name: 'Вианор', sname: 'ВіанHръ' },
      { id: 164, name: 'Вивиан', sname: 'Вівіaнъ' },
      { id: 165, name: 'Викентий', sname: 'Вікeнтій' },
      { id: 166, name: 'Викторин', sname: 'Вікторjнъ' },
      { id: 167, name: 'Виктор', sname: 'Вjкторъ' },
      { id: 168, name: 'Виталий', sname: 'Вітaлій' },
      { id: 169, name: 'Вит', sname: 'Вjтъ' },
      { id: 170, name: 'Владимир', sname: 'Влади1міръ' },
      { id: 171, name: 'Власий', sname: 'Влaсій' },
      { id: 172, name: 'Вонифатий', sname: 'Воніфaтій' },
      { id: 173, name: 'Всеволод', sname: 'Всeволодъ' },
      { id: 174, name: 'Вукол', sname: 'Вук0лъ' },
      { id: 175, name: 'Вячеслав', sname: 'Вzчеслaвъ' },
      { id: 176, name: 'Вифоний', sname: 'Вmf0ній' },
      { id: 177, name: 'Гаведдай', sname: 'Гаведдaй' },
      { id: 178, name: 'Гавиний', sname: 'Гавjній' },
      { id: 179, name: 'Гавриил', sname: 'Гавріи1лъ' },
      { id: 180, name: 'Гаий', sname: 'Гaій' },
      { id: 181, name: 'Галактион', sname: 'ГалактіHнъ' },
      { id: 182, name: 'Гамалиил', sname: 'Гамаліи1лъ' },
      { id: 183, name: 'Геласий', sname: 'Гелaсій' },
      { id: 184, name: 'Гемелл', sname: 'Гемeллъ' },
      { id: 185, name: 'Геннадий', sname: 'Геннaдій' },
      { id: 186, name: 'Герасим', sname: 'Герaсімъ' },
      { id: 187, name: 'Гервасий', sname: 'Гервaсій' },
      { id: 188, name: 'Герман', sname: 'Гeрманъ' },
      { id: 189, name: 'Геронтий', sname: 'Гер0нтій' },
      { id: 190, name: 'Георгий', sname: 'ГеHргій' },
      { id: 191, name: 'Гигантий', sname: 'Гігaнтій' },
      { id: 192, name: 'Глеб', sname: 'Глёбъ' },
      { id: 193, name: 'Гликерий', sname: 'Глmкeрій' },
      { id: 194, name: 'Горгий', sname: 'Г0ргій' },
      { id: 195, name: 'Горгоний', sname: 'Горг0ній' },
      { id: 196, name: 'Гордиан', sname: 'Гордіaнъ' },
      { id: 197, name: 'Гордий', sname: 'Гордjй' },
      { id: 198, name: 'Григорий', sname: 'Григ0рій' },
      { id: 199, name: 'Гурий', sname: 'Гyрій' },
      { id: 200, name: 'Давид', sname: 'Давjдъ' },
      { id: 201, name: 'Давикт', sname: 'Давjктъ' },
      { id: 202, name: 'Дада', sname: 'Дaда' },
      { id: 203, name: 'Далмат', sname: 'Далмaтъ' },
      { id: 204, name: 'Дамиан', sname: 'Даміaнъ' },
      { id: 205, name: 'Данакт', sname: 'Данaктъ' },
      { id: 206, name: 'Даниил', sname: 'Даніи1лъ' },
      { id: 207, name: 'Дасий', sname: 'Дaсій' },
      { id: 208, name: 'Димитриан', sname: 'Димитріaнъ' },
      { id: 209, name: 'Дмитрий', sname: 'Дими1трій' },
      { id: 210, name: 'Дисан', sname: 'Дисaнъ' },
      { id: 211, name: 'Дидим', sname: 'ДідЂмъ' },
      { id: 212, name: 'Дий', sname: 'Дjй' },
      { id: 213, name: 'Диодот', sname: 'Діод0тъ' },
      { id: 214, name: 'Диодор', sname: 'ДіодHръ' },
      { id: 215, name: 'Диомид', sname: 'Діоми1дъ' },
      { id: 216, name: 'Дионисий', sname: 'ДіонЂсій' },
      { id: 217, name: 'Диоскор', sname: 'Діоск0ръ' },
      { id: 218, name: 'Дисидерий', sname: 'Дісідeрій' },
      { id: 219, name: 'Дометиан', sname: 'Дометіaнъ' },
      { id: 220, name: 'Дометий', sname: 'Домeтій' },
      { id: 221, name: 'Домнин', sname: 'Домнjнъ' },
      { id: 222, name: 'Домн', sname: 'Д0мнъ' },
      { id: 223, name: 'Донат', sname: 'Донaтъ' },
      { id: 224, name: 'Доримедонт', sname: 'Дорmмед0нтъ' },
      { id: 225, name: 'Досифей', sname: 'Досіfeй' },
      { id: 226, name: 'Дукитий', sname: 'Дукjтій' },
      { id: 227, name: 'Дула', sname: 'Дyла' },
      { id: 228, name: 'Дорофей', sname: 'Дwроfeй' },
      { id: 229, name: 'Едесий', sname: 'Е#дeсій' },
      { id: 230, name: 'Екдикий', sname: 'Е#кдjкій' },
      { id: 231, name: 'Екдит', sname: 'Е#кдjтъ' },
      { id: 232, name: 'Елеазар', sname: 'Е#леазaръ' },
      { id: 233, name: 'Елезвой', sname: 'Е#лезв0й' },
      { id: 234, name: 'Елевсипп', sname: 'Е#леvсjппъ' },
      { id: 235, name: 'Елевферий', sname: 'Е#леvfeрій' },
      { id: 236, name: 'Елима', sname: 'Е#ли1ма' },
      { id: 237, name: 'Елисей', sname: 'Е#ліссeй' },
      { id: 238, name: 'Елладий', sname: 'Е#ллaдій' },
      { id: 239, name: 'Еллий', sname: 'Е$ллій' },
      { id: 240, name: 'Елпидий', sname: 'Е#лпjдій' },
      { id: 241, name: 'Елпидифор', sname: 'Е#лпідіф0ръ' },
      { id: 242, name: 'Емельян', sname: 'Е#міліaнъ' },
      { id: 243, name: 'Епафрас', sname: 'Е#пафрaсъ' },
      { id: 244, name: 'Епафродит', sname: 'Е#пафродjтъ' },
      { id: 245, name: 'Епенет', sname: 'Е#пенeтъ' },
      { id: 246, name: 'Епиктет', sname: 'Е#піктeтъ' },
      { id: 247, name: 'Епимах', sname: 'Е#пімaхъ' },
      { id: 248, name: 'Епифаний', sname: 'Е#піфaній' },
      { id: 249, name: 'Епполоний', sname: 'Е#ппол0ній' },
      { id: 250, name: 'Еразм', sname: 'Е#рaзмъ' },
      { id: 251, name: 'Ераст', sname: 'Е#рaстъ' },
      { id: 252, name: 'Ермей', sname: 'Е#рмeй' },
      { id: 253, name: 'Ерминингельд', sname: 'Е#рмініггeлдъ' },
      { id: 254, name: 'Ермий', sname: 'Е#рмjй' },
      { id: 255, name: 'Ермипп', sname: 'Е#рмjппъ' },
      { id: 256, name: 'Ермоген', sname: 'Е#рмогeнъ' },
      { id: 257, name: 'Ермократ', sname: 'Е#рмокрaтъ' },
      { id: 258, name: 'Ермолай', sname: 'Е#рмолaй' },
      { id: 259, name: 'Ерма', sname: 'Е$рма' },
      { id: 260, name: 'Ерм', sname: 'Е$рмъ' },
      { id: 261, name: 'Ермил', sname: 'Е#рмЂлъ' },
      { id: 262, name: 'Ерос', sname: 'Е$рwсъ' },
      { id: 263, name: 'Еспер', sname: 'Е$сперъ' },
      { id: 264, name: 'Ефив', sname: 'Е#фjвъ' },
      { id: 265, name: 'Еврем', sname: 'Е#фрeмъ' },
      { id: 266, name: 'Ексакустодиан', sname: 'Е#xакустwдіaнъ' },
      { id: 267, name: 'Еферий', sname: 'Е#feрій' },
      { id: 268, name: 'Евангел', sname: 'Е#vаггeлъ' },
      { id: 269, name: 'Еварест', sname: 'Е#vарeстъ' },
      { id: 270, name: 'Евелпист', sname: 'Е#vелпjстъ' },
      { id: 271, name: 'Еввентий', sname: 'Е#vвeнтій' },
      { id: 272, name: 'Еввул', sname: 'Е#vвyлъ' },
      { id: 273, name: 'Евгений', sname: 'Е#vгeній' },
      { id: 274, name: 'Евграф', sname: 'Е#vгрaфъ' },
      { id: 275, name: 'Евдоким', sname: 'Е#vдокjмъ' },
      { id: 276, name: 'Евдоксий', sname: 'Е#vдоxjй' },
      { id: 277, name: 'Евиласий', sname: 'Е#vілaсій' },
      { id: 278, name: 'Евкарпий', sname: 'Е#vкaрпій' },
      { id: 279, name: 'Евклей', sname: 'Е#vклeй' },
      { id: 280, name: 'Евлампий', sname: 'Е#vлaмпій' },
      { id: 281, name: 'Евлогий', sname: 'Е#vл0гій' },
      { id: 282, name: 'Евмений', sname: 'Е#vмeній' },
      { id: 283, name: 'Евникиан', sname: 'Е#vнікіaнъ' },
      { id: 284, name: 'Евноик', sname: 'Е#vн0ікъ' },
      { id: 285, name: 'Евод', sname: 'Е#v0дъ' },
      { id: 286, name: 'Евпл', sname: 'Е$vплъ' },
      { id: 287, name: 'Евпор', sname: 'Е#vп0ръ' },
      { id: 288, name: 'Евсевий', sname: 'Е#vсeвій' },
      { id: 289, name: 'Евсевон', sname: 'Е#vсевHнъ' },
      { id: 290, name: 'Евсеон', sname: 'Е#vсеHнъ' },
      { id: 291, name: 'Евсигний', sname: 'Е#vсmгнjй' },
      { id: 292, name: 'Евстафий', sname: 'Е#vстafій' },
      { id: 293, name: 'Евстохий', sname: 'Е#vст0хій' },
      { id: 294, name: 'Евстратий', sname: 'Е#vстрaтій' },
      { id: 295, name: 'Евсхимон', sname: 'Е#vсхи1мwнъ' },
      { id: 296, name: 'Евтропий', sname: 'Е#vтр0пій' },
      { id: 297, name: 'Евтихиан', sname: 'Е#vтmхіaнъ' },
      { id: 298, name: 'Евтихий', sname: 'Е#vтЂхій' },
      { id: 299, name: 'Евтих', sname: 'Е#vтЂхъ' },
      { id: 300, name: 'Евфрасий', sname: 'Е#vфрaсій' },
      { id: 301, name: 'Евфросин', sname: 'Е#vфросЂнъ' },
      { id: 302, name: 'Евпсихий', sname: 'Е#vpЂхій' },
      { id: 303, name: 'Ефим', sname: 'Е#vfЂмій' },
      { id: 304, name: 'Закхей', sname: 'Закхeй' },
      { id: 305, name: 'Захар', sname: 'Захaріа' },
      { id: 306, name: 'Зевин', sname: 'Зевjнъ' },
      { id: 307, name: 'Зина', sname: 'Зи1на' },
      { id: 308, name: 'Зиновий', sname: 'Зин0вій' },
      { id: 309, name: 'Зинон', sname: 'ЗинHнъ' },
      { id: 310, name: 'Зоил', sname: 'Зwи1лъ' },
      { id: 311, name: 'Зосима', sname: 'Зwсjма' },
      { id: 312, name: 'Зотик', sname: 'ЗHтікъ' },
      { id: 313, name: 'Игафракс', sname: 'И#гafраxъ' },
      { id: 314, name: 'Игорь', sname: 'И$горь' },
      { id: 315, name: 'Илья', sname: 'И#ліA' },
      { id: 316, name: 'Илиан', sname: 'И#ліaнъ' },
      { id: 317, name: 'Илий', sname: 'И#лjй' },
      { id: 318, name: 'Илиодор', sname: 'И#ліодHръ' },
      { id: 319, name: 'Иракламвон', sname: 'И#раклaмвwнъ' },
      { id: 320, name: 'Ираклемон', sname: 'И#раклeмwнъ' },
      { id: 321, name: 'Ираклий', sname: 'И#рaклій' },
      { id: 322, name: 'Иродион', sname: 'И#рwдіHнъ' },
      { id: 323, name: 'Исайя', sname: 'И#сaіа' },
      { id: 324, name: 'Иской', sname: 'И#ск0й' },
      { id: 325, name: 'Исихий', sname: 'И#сЂхій' },
      { id: 326, name: 'Иадор', sname: 'ЇадHръ' },
      { id: 327, name: 'Иакисхол', sname: 'Їакісх0лъ' },
      { id: 328, name: 'Яков', sname: 'Їaкwвъ' },
      { id: 329, name: 'Иамвлих', sname: 'Їамвлjхъ' },
      { id: 330, name: 'Ианикит', sname: 'Їаніки1тъ' },
      { id: 331, name: 'Январий', sname: 'Їаннуaрій' },
      { id: 332, name: 'Иасон', sname: 'Їaсwнъ' },
      { id: 333, name: 'Игнатий', sname: 'Їгнaтій' },
      { id: 334, name: 'Иезекииль', sname: 'Їезекіи1ль' },
      { id: 335, name: 'Иеракс', sname: 'Їерaxъ' },
      { id: 336, name: 'Иеремия', sname: 'Їеремjа' },
      { id: 337, name: 'Иеремий', sname: 'Їеремjй' },
      { id: 338, name: 'Иероним', sname: 'ЇерwнЂмъ' },
      { id: 339, name: 'Иерофей', sname: 'Їероfeй' },
      { id: 340, name: 'Иерон', sname: 'ЇерHнъ' },
      { id: 341, name: 'Иисус', sname: 'Їисyсъ' },
      { id: 342, name: 'Иларий', sname: 'Їлaрій' },
      { id: 343, name: 'Иларион', sname: 'ЇларіHнъ' },
      { id: 344, name: 'Индис', sname: 'Јндисъ' },
      { id: 345, name: 'Инна', sname: 'Јнна' },
      { id: 346, name: 'Иннокентий', sname: 'Їннокeнтій' },
      { id: 347, name: 'Ипполит', sname: 'ЇпполЂтъ' },
      { id: 348, name: 'Иринарх', sname: 'Їринaрхъ' },
      { id: 349, name: 'Ириней', sname: 'Їринeй' },
      { id: 350, name: 'Ириний', sname: 'Їри1ній' },
      { id: 351, name: 'Исаакий', sname: 'Їсаaкій' },
      { id: 352, name: 'Исаак', sname: 'Їсаaкъ' },
      { id: 353, name: 'Исакий', sname: 'Їсaкій' },
      { id: 354, name: 'Исавр', sname: 'Їсavръ' },
      { id: 355, name: 'Исидор', sname: 'Їсjдwръ' },
      { id: 356, name: 'Исмаил', sname: 'Їсмaилъ' },
      { id: 357, name: 'Истукарий', sname: 'Їстукaрій' },
      { id: 358, name: 'Ювеналий', sname: 'Їувенaлій' },
      { id: 359, name: 'Иувентин', sname: 'Їувентjнъ' },
      { id: 360, name: 'Иуда', sname: 'Їyда' },
      { id: 361, name: 'Юлиан', sname: 'Їуліaнъ' },
      { id: 362, name: 'Иулий', sname: 'Їyлій' },
      { id: 363, name: 'Иустиниан', sname: 'Їустініaнъ' },
      { id: 364, name: 'Иустин', sname: 'Їустjнъ' },
      { id: 365, name: 'Иуст', sname: 'Їyстъ' },
      { id: 366, name: 'Иоад', sname: 'Їwaдъ' },
      { id: 367, name: 'Иоаким', sname: 'Їwакjмъ' },
      { id: 368, name: 'Иоанникий', sname: 'Їwаннjкій' },
      { id: 369, name: 'Иван', sname: 'Їwaннъ' },
      { id: 370, name: 'Иоасаф', sname: 'Їwасaфъ' },
      { id: 371, name: 'Иов', sname: 'Јwвъ' },
      { id: 372, name: 'Иоиль', sname: 'Їwи1ль' },
      { id: 373, name: 'Иона', sname: 'ЇHна' },
      { id: 374, name: 'Иосиф', sname: 'ЇHсифъ' },
      { id: 375, name: 'Иосий', sname: 'Їwсjй' },
      { id: 376, name: 'Каллиник', sname: 'Каллінjкъ' },
      { id: 377, name: 'Каллиопий', sname: 'Каллі0пій' },
      { id: 378, name: 'Каллистрат', sname: 'Каллістрaтъ' },
      { id: 379, name: 'Каллист', sname: 'Кaллістъ' },
      { id: 380, name: 'Калуф', sname: 'Калyфъ' },
      { id: 381, name: 'Кандид', sname: 'Кандjдъ' },
      { id: 382, name: 'Кантидиан', sname: 'Кантідіaнъ' },
      { id: 383, name: 'Кантидий', sname: 'Кантjдій' },
      { id: 384, name: 'Капитон', sname: 'Капjтwнъ' },
      { id: 385, name: 'Карион', sname: 'КаріHнъ' },
      { id: 386, name: 'Карп', sname: 'Кaрпъ' },
      { id: 387, name: 'Картерий', sname: 'Картeрій' },
      { id: 388, name: 'Кассиан', sname: 'Кассіaнъ' },
      { id: 389, name: 'Касторий', sname: 'Каст0рій' },
      { id: 390, name: 'Кастор', sname: 'Кaсторъ' },
      { id: 391, name: 'Кастрихий', sname: 'Кастрjхій' },
      { id: 392, name: 'Кастул', sname: 'Кастyлъ' },
      { id: 393, name: 'Катерий', sname: 'Катeрій' },
      { id: 394, name: 'Катун', sname: 'Катyнъ' },
      { id: 395, name: 'Кварт', sname: 'Квaртъ' },
      { id: 396, name: 'Квинтилиан', sname: 'Квінтіліaнъ' },
      { id: 397, name: 'Целестин', sname: 'Келестjнъ' },
      { id: 398, name: 'Келсий', sname: 'Келсjй' },
      { id: 399, name: 'Кенсорин', sname: 'Кенсорjнъ' },
      { id: 400, name: 'Кесарий', sname: 'Кесaрій' },
      { id: 401, name: 'Кесарь', sname: 'Кeсарь' },
      { id: 402, name: 'Кирик', sname: 'Ки1рmкъ' },
      { id: 403, name: 'Кифа', sname: 'Ки1фа' },
      { id: 404, name: 'Киндей', sname: 'Кіндeй' },
      { id: 405, name: 'Кинтилиан', sname: 'Кінтіліaнъ' },
      { id: 406, name: 'Кион', sname: 'КіHнъ' },
      { id: 407, name: 'Клавдиан', sname: 'Клаvдіaнъ' },
      { id: 408, name: 'Клавдий', sname: 'Клаvдjй' },
      { id: 409, name: 'Клеоник', sname: 'Клеонjкъ' },
      { id: 410, name: 'Клеопа', sname: 'Кле0па' },
      { id: 411, name: 'Климент', sname: 'Кли1ментъ' },
      { id: 412, name: 'Кодрат', sname: 'Кодрaтъ' },
      { id: 413, name: 'Коинт', sname: 'Коjнтъ' },
      { id: 414, name: 'Конкордий', sname: 'Конк0рдій' },
      { id: 415, name: 'Конон', sname: 'К0нwнъ' },
      { id: 416, name: 'Коприй', sname: 'К0прій' },
      { id: 417, name: 'Корнилий', sname: 'Корнjлій' },
      { id: 418, name: 'Корнут', sname: 'Корнyтъ' },
      { id: 419, name: 'Коронат', sname: 'Корwнaтъ' },
      { id: 420, name: 'Косьма', sname: 'КосмA' },
      { id: 421, name: 'Крискентиан', sname: 'Крискентіaнъ' },
      { id: 422, name: 'Крискент', sname: 'Крискeнтъ' },
      { id: 423, name: 'Крисп', sname: 'Крjспъ' },
      { id: 424, name: 'Кронид', sname: 'Кронjдъ' },
      { id: 425, name: 'Куарт', sname: 'Куaртъ' },
      { id: 426, name: 'Кукша', sname: 'Кyкша' },
      { id: 427, name: 'Куфий', sname: 'Кyфій' },
      { id: 428, name: 'Константин', sname: 'Кwнстантjнъ' },
      { id: 429, name: 'Киприан', sname: 'Кmпріaнъ' },
      { id: 430, name: 'Кириак', sname: 'Кmріaкъ' },
      { id: 431, name: 'Кирилл', sname: 'Кmрjллъ' },
      { id: 432, name: 'Кирин', sname: 'Кmрjнъ' },
      { id: 433, name: 'Кирион', sname: 'КmріHнъ' },
      { id: 434, name: 'Лазарь', sname: 'Лaзарь' },
      { id: 435, name: 'Лампад', sname: 'Лaмпадъ' },
      { id: 436, name: 'Лаодикий', sname: 'Лаодікjй' },
      { id: 437, name: 'Ларгий', sname: 'Лaргій' },
      { id: 438, name: 'Лаврентий', sname: 'Лаvрeнтій' },
      { id: 439, name: 'Лавр', sname: 'Лavръ' },
      { id: 440, name: 'Лев', sname: 'Лeвъ' },
      { id: 441, name: 'Леонтий', sname: 'Ле0нтій' },
      { id: 442, name: 'Леонт', sname: 'Лeонтъ' },
      { id: 443, name: 'Леонид', sname: 'Леwнjдъ' },
      { id: 444, name: 'Левкий', sname: 'Леvкjй' },
      { id: 445, name: 'Ливерий', sname: 'Лівeрій' },
      { id: 446, name: 'Лимней', sname: 'Лімнeй' },
      { id: 447, name: 'Лимний', sname: 'Лімнjй' },
      { id: 448, name: 'Лин', sname: 'Лjнъ' },
      { id: 449, name: 'Лонгин', sname: 'Л0ггінъ' },
      { id: 450, name: 'Лука', sname: 'ЛукA' },
      { id: 451, name: 'Лукиан', sname: 'Лукіaнъ' },
      { id: 452, name: 'Лукий', sname: 'Лукjй' },
      { id: 453, name: 'Лукиллиан', sname: 'Лукілліaнъ' },
      { id: 454, name: 'Лупп', sname: 'Лyппъ' },
      { id: 455, name: 'Лот', sname: 'ЛHтъ' },
      { id: 456, name: 'Ликарион', sname: 'ЛmкаріHнъ' },
      { id: 457, name: 'Лисимах', sname: 'Лmсімaхъ' },
      { id: 458, name: 'Магн', sname: 'Мaгнъ' },
      { id: 459, name: 'Макарий', sname: 'Макaрій' },
      { id: 460, name: 'Македоний', sname: 'Макед0ній' },
      { id: 461, name: 'Македон', sname: 'МакедHнъ' },
      { id: 462, name: 'Маровий', sname: 'Макр0вій' },
      { id: 463, name: 'Малахия', sname: 'Малахjа' },
      { id: 464, name: 'Мамант', sname: 'Мaмантъ' },
      { id: 465, name: 'Маммий', sname: 'Маммjй' },
      { id: 466, name: 'Мануил', sname: 'Манyилъ' },
      { id: 467, name: 'Мардарий', sname: 'Мардaрій' },
      { id: 468, name: 'Мардоний', sname: 'Мард0ній' },
      { id: 469, name: 'Мариав', sname: 'Маріaвъ' },
      { id: 470, name: 'Марин', sname: 'Марjнъ' },
      { id: 471, name: 'Маркеллин', sname: 'Маркеллjнъ' },
      { id: 472, name: 'Маркелл', sname: 'Маркeллъ' },
      { id: 473, name: 'Маркиан', sname: 'Маркіaнъ' },
      { id: 474, name: 'Марк', sname: 'Мaркъ' },
      { id: 475, name: 'Марсалий', sname: 'Марсaлій' },
      { id: 476, name: 'Мартиниан', sname: 'Мартініaнъ' },
      { id: 477, name: 'Мартин', sname: 'Мартjнъ' },
      { id: 478, name: 'Мартирий', sname: 'МартЂрій' },
      { id: 479, name: 'Маруф', sname: 'Марyfъ' },
      { id: 480, name: 'Мар', sname: 'Мaръ' },
      { id: 481, name: 'Марон', sname: 'Мaрwнъ' },
      { id: 482, name: 'Матфей', sname: 'Матfeй' },
      { id: 483, name: 'Матфий', sname: 'Матfjй' },
      { id: 484, name: 'Максиан', sname: 'Маxіaнъ' },
      { id: 485, name: 'Максимиан', sname: 'Маxіміaнъ' },
      { id: 486, name: 'Максимилиан', sname: 'Маxіміліaнъ' },
      { id: 487, name: 'Максим', sname: 'Маxjмъ' },
      { id: 488, name: 'Маврикий', sname: 'Маvрjкій' },
      { id: 489, name: 'Мавр', sname: 'Мavръ' },
      { id: 490, name: 'Мавсима', sname: 'Маvсjма' },
      { id: 491, name: 'Медимн', sname: 'Медjмнъ' },
      { id: 492, name: 'Меласипп', sname: 'Меласjппъ' },
      { id: 493, name: 'Мелетий', sname: 'Мелeтій' },
      { id: 494, name: 'Мелевсипп', sname: 'Мелеvсjппъ' },
      { id: 495, name: 'Мелитон', sname: 'МелітHнъ' },
      { id: 496, name: 'Мемнон', sname: 'Мeмнwнъ' },
      { id: 497, name: 'Менандр', sname: 'Менaндръ' },
      { id: 498, name: 'Менея', sname: 'Менeа' },
      { id: 499, name: 'Менигн', sname: 'Менjгнъ' },
      { id: 500, name: 'Меркурий', sname: 'Меркyрій' },
      { id: 501, name: 'Мертий', sname: 'Мeртій' },
      { id: 502, name: 'Мефодий', sname: 'Меf0дій' },
      { id: 503, name: 'Мина', sname: 'Ми1на' },
      { id: 504, name: 'Минеон', sname: 'МинеHнъ' },
      { id: 505, name: 'Минсифей', sname: 'Минсіfeй' },
      { id: 506, name: 'Митрофан', sname: 'Митрофaнъ' },
      { id: 507, name: 'Миан', sname: 'Міaнъ' },
      { id: 508, name: 'Милиссен', sname: 'Міли1ссенъ' },
      { id: 509, name: 'Милий', sname: 'Мілjй' },
      { id: 510, name: 'Миракс', sname: 'Мірaxъ' },
      { id: 511, name: 'Мисаил', sname: 'Місаи1лъ' },
      { id: 512, name: 'Михаил', sname: 'Міхаи1лъ' },
      { id: 513, name: 'Михей', sname: 'Міхeй' },
      { id: 514, name: 'Модест', sname: 'Модeстъ' },
      { id: 515, name: 'Мокий', sname: 'Мwкjй' },
      { id: 516, name: 'Моисей', sname: 'Мwmсeй' },
      { id: 517, name: 'Мигдоний', sname: 'Мmгд0ній' },
      { id: 518, name: 'Мирон', sname: 'МЂрwнъ' },
      { id: 519, name: 'Назарий', sname: 'Назaрій' },
      { id: 520, name: 'Наркисс', sname: 'Наркjссъ' },
      { id: 521, name: 'Наум', sname: 'Наyмъ' },
      { id: 522, name: 'Нафанаил', sname: 'Наfанaилъ' },
      { id: 523, name: 'Нектарий', sname: 'Нектaрій' },
      { id: 524, name: 'Неофит', sname: 'НеофЂтъ' },
      { id: 525, name: 'Нестор', sname: 'Нeстwръ' },
      { id: 526, name: 'Неон', sname: 'НеHнъ' },
      { id: 527, name: 'Нирса', sname: 'Ни1рса' },
      { id: 528, name: 'Нифонт', sname: 'Ни1fwнтъ' },
      { id: 529, name: 'Никандр', sname: 'Нікaндръ' },
      { id: 530, name: 'Никанор', sname: 'НіканHръ' },
      { id: 531, name: 'Никита', sname: 'Ніки1та' },
      { id: 532, name: 'Никифор', sname: 'Ніки1форъ' },
      { id: 533, name: 'Никодим', sname: 'Нікоди1мъ' },
      { id: 534, name: 'Никола', sname: 'Нік0ла' },
      { id: 535, name: 'Николай', sname: 'Ніколaй' },
      { id: 536, name: 'Никострат', sname: 'Нікострaтъ' },
      { id: 537, name: 'Никон', sname: 'Нjкwнъ' },
      { id: 538, name: 'Нил', sname: 'Нjлъ' },
      { id: 539, name: 'Нит', sname: 'Нjтъ' },
      { id: 540, name: 'Никтополион', sname: 'НmктополіHнъ' },
      { id: 541, name: 'Олег', sname: 'Nлeгъ' },
      { id: 542, name: 'Олимпан', sname: 'Nлmмпaнъ' },
      { id: 543, name: 'Олимпий', sname: 'NлЂмпій' },
      { id: 544, name: 'Олимп', sname: 'NлЂмпъ' },
      { id: 545, name: 'Онисий', sname: 'Nни1сій' },
      { id: 546, name: 'Онисим', sname: 'Nни1сімъ' },
      { id: 547, name: 'Онисифор', sname: 'Nнисjфоръ' },
      { id: 548, name: 'Онуфрий', sname: 'Nнyфрій' },
      { id: 549, name: 'Орентий', sname: 'Nрeнтій' },
      { id: 550, name: 'Орест', sname: 'Nрeстъ' },
      { id: 551, name: 'Острихий', sname: 'NстрЂхій' },
      { id: 552, name: 'Панкрат', sname: 'Пагкрaтій' },
      { id: 553, name: 'Панхарий', sname: 'Пагхaрій' },
      { id: 554, name: 'Паисий', sname: 'Паjсій' },
      { id: 555, name: 'Пактовий', sname: 'Пакт0вій' },
      { id: 556, name: 'Палладий', sname: 'Паллaдій' },
      { id: 557, name: 'Памва', sname: 'Пaмва' },
      { id: 558, name: 'Памвон', sname: 'Пaмвwнъ' },
      { id: 559, name: 'Памфалон', sname: 'ПамфалHнъ' },
      { id: 560, name: 'Памфамир', sname: 'Памфамjръ' },
      { id: 561, name: 'Памфил', sname: 'Памфjлъ' },
      { id: 562, name: 'Пансофий', sname: 'Панс0фій' },
      { id: 563, name: 'Пантелеимон', sname: 'Пантелеи1монъ' },
      { id: 564, name: 'Папа', sname: 'Пaпа' },
      { id: 565, name: 'Папий', sname: 'Пaпій' },
      { id: 566, name: 'Папила', sname: 'ПапЂла' },
      { id: 567, name: 'Парамон', sname: 'Парам0нъ' },
      { id: 568, name: 'Пард', sname: 'Пaрдъ' },
      { id: 569, name: 'Паригорий', sname: 'Париг0рій' },
      { id: 570, name: 'Пармений', sname: 'Пармeній' },
      { id: 571, name: 'Пармен', sname: 'Пармeнъ' },
      { id: 572, name: 'Парод', sname: 'Пар0дъ' },
      { id: 573, name: 'Парфений', sname: 'Парfeній' },
      { id: 574, name: 'Пасикрат', sname: 'Пасікрaтъ' },
      { id: 575, name: 'Патапий', sname: 'Патaпій' },
      { id: 576, name: 'Патермуфий', sname: 'Патермyfій' },
      { id: 577, name: 'Патрикий', sname: 'Патрjкій' },
      { id: 578, name: 'Патроф', sname: 'Патр0въ' },
      { id: 579, name: 'Патрокл', sname: 'Патр0клъ' },
      { id: 580, name: 'Пафнутий', sname: 'Пафнyтій' },
      { id: 581, name: 'Пахомий', sname: 'ПахHмій' },
      { id: 582, name: 'Павел', sname: 'Пavелъ' },
      { id: 583, name: 'Павлин', sname: 'Паvлjнъ' },
      { id: 584, name: 'Павсикакий', sname: 'Паvсікaкій' },
      { id: 585, name: 'Павсилип', sname: 'ПаvсілЂпъ' },
      { id: 586, name: 'Пелий', sname: 'Пeлій' },
      { id: 587, name: 'Перегрин', sname: 'Перегрjнъ' },
      { id: 588, name: 'Петр', sname: 'Пeтръ' },
      { id: 589, name: 'Пеон', sname: 'ПеHнъ' },
      { id: 590, name: 'Пигасий', sname: 'Пигaсій' },
      { id: 591, name: 'Пимен', sname: 'Пjменъ' },
      { id: 592, name: 'Пинна', sname: 'Пjнна' },
      { id: 593, name: 'Пионий', sname: 'Пі0ній' },
      { id: 594, name: 'Пист', sname: 'Пjстъ' },
      { id: 595, name: 'Платон', sname: 'ПлатHнъ' },
      { id: 596, name: 'Полувий', sname: 'Полyвій' },
      { id: 597, name: 'Полиен', sname: 'Полmeнъ' },
      { id: 598, name: 'Полиевкт', sname: 'Полmevктъ' },
      { id: 599, name: 'Поликарп', sname: 'Полmкaрпъ' },
      { id: 600, name: 'Полихроний', sname: 'Полmхр0ній' },
      { id: 601, name: 'Помпей', sname: 'Помпeй' },
      { id: 602, name: 'Помпий', sname: 'Помпjй' },
      { id: 603, name: 'Понтий', sname: 'П0нтій' },
      { id: 604, name: 'Поплий', sname: 'Поплjй' },
      { id: 605, name: 'Порфирий', sname: 'ПорфЂрій' },
      { id: 606, name: 'Потит', sname: 'Поти1тъ' },
      { id: 607, name: 'Прилидиан', sname: 'Прилидіaнъ' },
      { id: 608, name: 'Приск', sname: 'Прjскъ' },
      { id: 609, name: 'Пров', sname: 'Пр0въ' },
      { id: 610, name: 'Прокесс', sname: 'Прокeссъ' },
      { id: 611, name: 'Прокл', sname: 'Пр0клъ' },
      { id: 612, name: 'Прокопий', sname: 'Прок0пій' },
      { id: 613, name: 'Прокул', sname: 'Прокyлъ' },
      { id: 614, name: 'Протасий', sname: 'Протaсій' },
      { id: 615, name: 'Протерий', sname: 'Протeрій' },
      { id: 616, name: 'Прохор', sname: 'Пр0хwръ' },
      { id: 617, name: 'Протоген', sname: 'Прwтогeнъ' },
      { id: 618, name: 'Протолеон', sname: 'ПрwтолеHнъ' },
      { id: 619, name: 'Прот', sname: 'ПрHтъ' },
      { id: 620, name: 'Пуд', sname: 'Пyдъ' },
      { id: 621, name: 'Пуплий', sname: 'Пуплjй' },
      { id: 622, name: 'Равула', sname: 'Равyла' },
      { id: 623, name: 'Разумник', sname: 'Разyмникъ' },
      { id: 624, name: 'Реас', sname: 'Рeасъ' },
      { id: 625, name: 'Ревокат', sname: 'Ревокaтъ' },
      { id: 626, name: 'Римма', sname: 'Рjмма' },
      { id: 627, name: 'Рикс', sname: 'Рjxъ' },
      { id: 628, name: 'Родион', sname: 'РодіHнъ' },
      { id: 629, name: 'Родопиан', sname: 'Родопіaнъ' },
      { id: 630, name: 'Руфин', sname: 'Руфjнъ' },
      { id: 631, name: 'Руф', sname: 'Рyfъ' },
      { id: 632, name: 'Роман', sname: 'Рwмaнъ' },
      { id: 633, name: 'Ромил', sname: 'РwмЂлъ' },
      { id: 634, name: 'Савва', sname: 'Сaвва' },
      { id: 635, name: 'Савватий', sname: 'Саввaтій' },
      { id: 636, name: 'Савел', sname: 'Савeлъ' },
      { id: 637, name: 'Саверий', sname: 'Савeрій' },
      { id: 638, name: 'Савин', sname: 'Савjнъ' },
      { id: 639, name: 'Сакердон', sname: 'СакердHнъ' },
      { id: 640, name: 'Саламан', sname: 'Саламaнъ' },
      { id: 641, name: 'Самей', sname: 'Самeй' },
      { id: 642, name: 'Самуил', sname: 'Самуи1лъ' },
      { id: 643, name: 'Самон', sname: 'СамHнъ' },
      { id: 644, name: 'Сампсон', sname: 'СамpHнъ' },
      { id: 645, name: 'Сарвил', sname: 'Сарви1лъ' },
      { id: 646, name: 'Сасоний', sname: 'Сас0ній' },
      { id: 647, name: 'Саторин', sname: 'Саторjнъ' },
      { id: 648, name: 'Саторний', sname: 'Саторнjй' },
      { id: 649, name: 'Саторнил', sname: 'Саторни1лъ' },
      { id: 650, name: 'Саторнин', sname: 'Саторнjнъ' },
      { id: 651, name: 'Сатурнин', sname: 'Сатурнjнъ' },
      { id: 652, name: 'Сатир', sname: 'СатЂръ' },
      { id: 653, name: 'Севастиан', sname: 'Севастіaнъ' },
      { id: 654, name: 'Северин', sname: 'Севери1нъ' },
      { id: 655, name: 'Севериан', sname: 'Северіaнъ' },
      { id: 656, name: 'Севириан', sname: 'Севиріaнъ' },
      { id: 657, name: 'Севир', sname: 'Севи1ръ' },
      { id: 658, name: 'Секунд', sname: 'Секyндъ' },
      { id: 659, name: 'Селевкий', sname: 'Селеvкjй' },
      { id: 660, name: 'Селиний', sname: 'Сели1ній' },
      { id: 661, name: 'Сеннис', sname: 'Сеннjсъ' },
      { id: 662, name: 'Серапион', sname: 'СерапіHнъ' },
      { id: 663, name: 'Серафим', sname: 'Серафjмъ' },
      { id: 664, name: 'Серафион', sname: 'СерафіHнъ' },
      { id: 665, name: 'Сергий', sname: 'Сeргій' },
      { id: 666, name: 'Сивел', sname: 'Сjвелъ' },
      { id: 667, name: 'Сила', sname: 'Сjла' },
      { id: 668, name: 'Силан', sname: 'Сілaнъ' },
      { id: 669, name: 'Сильван', sname: 'Сілвaнъ' },
      { id: 670, name: 'Сильвестр', sname: 'Сілвeстръ' },
      { id: 671, name: 'Силуан', sname: 'Сілуaнъ' },
      { id: 672, name: 'Симон', sname: 'Сjмwнъ' },
      { id: 673, name: 'Сионий', sname: 'Сіонjй' },
      { id: 674, name: 'Сисиний', sname: 'Сісjній' },
      { id: 675, name: 'Сисой', sname: 'СісHй' },
      { id: 676, name: 'Сикст', sname: 'Сjxтъ' },
      { id: 677, name: 'Смарагд', sname: 'Смарaгдъ' },
      { id: 678, name: 'Солохон', sname: 'СолохHнъ' },
      { id: 679, name: 'Сонирил', sname: 'Сонирjлъ' },
      { id: 680, name: 'Софония', sname: 'Соф0ніа' },
      { id: 681, name: 'Спевсипп', sname: 'Спеvсjппъ' },
      { id: 682, name: 'Спиридон', sname: 'СпmрідHнъ' },
      { id: 683, name: 'Стахий', sname: 'СтахЂй' },
      { id: 684, name: 'Стефан', sname: 'Стефaнъ' },
      { id: 685, name: 'Стратоник', sname: 'Стратонjкъ' },
      { id: 686, name: 'Стратон', sname: 'СтратHнъ' },
      { id: 687, name: 'Стратор', sname: 'СтратHръ' },
      { id: 688, name: 'Суимвл', sname: 'Суjмвлъ' },
      { id: 689, name: 'Созонт', sname: 'Сwз0нтъ' },
      { id: 690, name: 'Сократ', sname: 'Сwкрaтъ' },
      { id: 691, name: 'Сосипатр', sname: 'Сwсіпaтръ' },
      { id: 692, name: 'Соссий', sname: 'СHссій' },
      { id: 693, name: 'Сосфен', sname: 'Сwсfeнъ' },
      { id: 694, name: 'Софроний', sname: 'Сwфр0ній' },
      { id: 695, name: 'Симеон', sname: 'СmмеHнъ' },
      { id: 696, name: 'Симфориан', sname: 'Сmмфоріaнъ' },
      { id: 697, name: 'Тарас', sname: 'Тарaсій' },
      { id: 698, name: 'Тарах', sname: 'Тарaхъ' },
      { id: 699, name: 'Татион', sname: 'ТатіHнъ' },
      { id: 700, name: 'Таврион', sname: 'ТаvріHнъ' },
      { id: 701, name: 'Терентий', sname: 'Терeнтій' },
      { id: 702, name: 'Тертий', sname: 'Тeртій' },
      { id: 703, name: 'Тивуртий', sname: 'Тівyртій' },
      { id: 704, name: 'Тигрий', sname: 'Тjгрій' },
      { id: 705, name: 'Тимолай', sname: 'Тімолaй' },
      { id: 706, name: 'Тимофей', sname: 'Тімоfeй' },
      { id: 707, name: 'Тимон', sname: 'Тjмwнъ' },
      { id: 708, name: 'Тит', sname: 'Тjтъ' },
      { id: 709, name: 'Транквилион', sname: 'Трагквіллjнъ' },
      { id: 710, name: 'Тривимий', sname: 'Трівjмій' },
      { id: 711, name: 'Трифиллий', sname: 'ТріфЂллій' },
      { id: 712, name: 'Трофим', sname: 'Трофjмъ' },
      { id: 713, name: 'Троадий', sname: 'Трwaдій' },
      { id: 714, name: 'Трифон', sname: 'ТрЂфwнъ' },
      { id: 715, name: 'Турвон', sname: 'Турв0нъ' },
      { id: 716, name: 'Тихик', sname: 'Тmхjкъ' },
      { id: 717, name: 'Тихон', sname: 'ТЂхwнъ' },
      { id: 718, name: 'Валентин', sname: 'Ўалентjнъ' },
      { id: 719, name: 'Валент', sname: 'Ўaлентъ' },
      { id: 720, name: 'Валериан', sname: 'Ўалеріaнъ' },
      { id: 721, name: 'Валерий', sname: 'Ўалeрій' },
      { id: 722, name: 'Уар', sname: 'Ўaръ' },
      { id: 723, name: 'Урван', sname: 'Ўрвaнъ' },
      { id: 724, name: 'Урпасиан', sname: 'Ўрпасіaнъ' },
      { id: 725, name: 'Усфазан', sname: 'Ўсfазaнъ' },
      { id: 726, name: 'Фавий', sname: 'Фaвій' },
      { id: 727, name: 'Фантин', sname: 'Фантjнъ' },
      { id: 728, name: 'Фармуфий', sname: 'Фармyfій' },
      { id: 729, name: 'Фарнакий', sname: 'Фарнaкій' },
      { id: 730, name: 'Фавстиан', sname: 'Фаvстіaнъ' },
      { id: 731, name: 'Фавст', sname: 'Фavстъ' },
      { id: 732, name: 'Филикиссим', sname: 'Филікjссімъ' },
      { id: 733, name: 'Филикс', sname: 'Фи1ліxъ' },
      { id: 734, name: 'Филагрий', sname: 'Філaгрій' },
      { id: 735, name: 'Филадельф', sname: 'Філадeлфъ' },
      { id: 736, name: 'Филарет', sname: 'Філарeтъ' },
      { id: 737, name: 'Филетер', sname: 'Філетeръ' },
      { id: 738, name: 'Филимон', sname: 'ФілимHнъ' },
      { id: 739, name: 'Филит', sname: 'Філи1тъ' },
      { id: 740, name: 'Филипп', sname: 'Філjппъ' },
      { id: 741, name: 'Фил', sname: 'Фjлъ' },
      { id: 742, name: 'Филогоний', sname: 'Філог0ній' },
      { id: 743, name: 'Филоктимон', sname: 'Філокти1мwнъ' },
      { id: 744, name: 'Филолог', sname: 'Філол0гъ' },
      { id: 745, name: 'Философ', sname: 'Філос0фъ' },
      { id: 746, name: 'Филофей', sname: 'Філоfeй' },
      { id: 747, name: 'Филумен', sname: 'Філумeнъ' },
      { id: 748, name: 'Финеес', sname: 'Фінеeсъ' },
      { id: 749, name: 'Фирмин', sname: 'Фірмjнъ' },
      { id: 750, name: 'Фирмос', sname: 'Фjрмосъ' },
      { id: 751, name: 'Флавиан', sname: 'Флавіaнъ' },
      { id: 752, name: 'Флавий', sname: 'Флaвій' },
      { id: 753, name: 'Флегонт', sname: 'Флeгонтъ' },
      { id: 754, name: 'Флорентий', sname: 'Флорeнтій' },
      { id: 755, name: 'Флор', sname: 'ФлHръ' },
      { id: 756, name: 'Форвин', sname: 'Форвjнъ' },
      { id: 757, name: 'Фортунат', sname: 'Фортунaтъ' },
      { id: 758, name: 'Фронтасий', sname: 'Фронтaсій' },
      { id: 759, name: 'Фрументий', sname: 'Фрумeнтій' },
      { id: 760, name: 'Фульвиан', sname: 'Фулвіaнъ' },
      { id: 761, name: 'Фуртунат', sname: 'Фуртунaтъ' },
      { id: 762, name: 'Фока', sname: 'ФHка' },
      { id: 763, name: 'Фостирий', sname: 'Фwсти1рій' },
      { id: 764, name: 'Фотий', sname: 'ФHтій' },
      { id: 765, name: 'Фотин', sname: 'Фwтjнъ' },
      { id: 766, name: 'Фот', sname: 'ФHтъ' },
      { id: 767, name: 'Харалампий', sname: 'Харалaмпій' },
      { id: 768, name: 'Харисим', sname: 'Харjсімъ' },
      { id: 769, name: 'Харитон', sname: 'ХарітHнъ' },
      { id: 770, name: 'Херимон', sname: 'Хери1мwнъ' },
      { id: 771, name: 'Христофор', sname: 'Хрістоф0ръ' },
      { id: 772, name: 'Хрисанф', sname: 'Хрmсaнfъ' },
      { id: 773, name: 'Хрисогон', sname: 'Хрmсог0нъ' },
      { id: 774, name: 'Хрисотель', sname: 'Хрmс0тель' },
      { id: 775, name: 'Худион', sname: 'ХудіHнъ' },
      { id: 776, name: 'Хуздазат', sname: 'Хуздазaтъ' },
      { id: 777, name: 'Ор', sname: 'W$ръ' },
      { id: 778, name: 'Осия', sname: 'Њсjа' },
      { id: 779, name: 'Ксанфий', sname: 'Xaнfій' },
      { id: 780, name: 'Ксенофонт', sname: 'XенофHнтъ' },
      { id: 781, name: 'Псой', sname: 'PHй' },
      { id: 782, name: 'Фаддей', sname: 'Fаддeй' },
      { id: 783, name: 'Фал', sname: 'Faлъ' },
      { id: 784, name: 'Фалалей', sname: 'Fалалeй' },
      { id: 785, name: 'Фалассий', sname: 'Fалaссій' },
      { id: 786, name: 'Фавмасий', sname: 'Fаvмaсій' },
      { id: 787, name: 'Феаген', sname: 'Fеагeнъ' },
      { id: 788, name: 'Фемелий', sname: 'Fемeлій' },
      { id: 789, name: 'Фемистоклей', sname: 'Fемістоклeй' },
      { id: 790, name: 'Феоген', sname: 'Fеогeнъ' },
      { id: 791, name: 'Феогнид', sname: 'Fеогнjдъ' },
      { id: 792, name: 'Феогний', sname: 'Fе0гній' },
      { id: 793, name: 'Феогност', sname: 'FеогнHстъ' },
      { id: 794, name: 'Феодосий', sname: 'Fеод0сій' },
      { id: 795, name: 'Феодот', sname: 'Fеод0тъ' },
      { id: 796, name: 'Феодох', sname: 'Fеод0хъ' },
      { id: 797, name: 'Феодул', sname: 'Fеодyлъ' },
      { id: 798, name: 'Феодорит', sname: 'Fеодwрjтъ' },
      { id: 799, name: 'Федор', sname: 'Fе0дwръ' },
      { id: 800, name: 'Феоктирист', sname: 'Fеоктирjстъ' },
      { id: 801, name: 'Феоктист', sname: 'Fеоктjстъ' },
      { id: 802, name: 'Феопемпт', sname: 'Fеопeмптъ' },
      { id: 803, name: 'Феопист', sname: 'Fеопjстъ' },
      { id: 804, name: 'Феопрепий', sname: 'Fеопрeпій' },
      { id: 805, name: 'Феостирикт', sname: 'Fеостирjктъ' },
      { id: 806, name: 'Феостих', sname: 'Fеостjхъ' },
      { id: 807, name: 'Феотекн', sname: 'Fеотeкнъ' },
      { id: 808, name: 'Феофан', sname: 'Fеофaнъ' },
      { id: 809, name: 'Феофил', sname: 'Fе0філъ' },
      { id: 810, name: 'Феофилакт', sname: 'Fеофmлaктъ' },
      { id: 811, name: 'Ферапонт', sname: 'Fерап0нтъ' },
      { id: 812, name: 'Ферм', sname: 'Feрмъ' },
      { id: 813, name: 'Феспесий', sname: 'Fеспeсій' },
      { id: 814, name: 'Феона', sname: 'FеHна' },
      { id: 815, name: 'Фифаил', sname: 'Fіфаи1лъ' },
      { id: 816, name: 'Фома', sname: 'FwмA' },
      { id: 817, name: 'Фирс', sname: 'FЂрсъ' },
      { id: 818, name: 'Иакинф', sname: 'V#акjнfъ' },
      { id: 819, name: 'Ипатий', sname: 'V#пaтій' },
      { id: 820, name: 'Иперехий', sname: 'V#перeхій' },
      { id: 821, name: 'Иперихий', sname: 'V#пери1хій' },
      { id: 822, name: 'Агапия', sname: 'Ґгaпіа' },
      { id: 823, name: 'Агафия', sname: 'Ґгafіа' },
      { id: 824, name: 'Агафоклия', sname: 'Ґгаfоклjа' },
      { id: 825, name: 'Агафоника', sname: 'Ґгаfонjка' },
      { id: 826, name: 'Агния', sname: 'Ґгнjа' },
      { id: 827, name: 'Агрипина', sname: 'Ґгрmпjна' },
      { id: 828, name: 'Акилина', sname: 'Ґкmлjна' },
      { id: 829, name: 'Александра', sname: 'Ґлеxaндра' },
      { id: 830, name: 'Алевтина', sname: 'Ґлеvтjна' },
      { id: 831, name: 'Алла', sname: 'Ѓлла' },
      { id: 832, name: 'Анастасия', sname: 'Ґнастасjа' },
      { id: 833, name: 'Анимаида', sname: 'Ґнімаjда' },
      { id: 834, name: 'Анимаиса', sname: 'Ґнімаjса' },
      { id: 835, name: 'Анна', sname: 'Ѓнна' },
      { id: 836, name: 'Антонина', sname: 'Ґнтwнjна' },
      { id: 837, name: 'Анфиса', sname: 'Ґнfjса' },
      { id: 838, name: 'Анфия', sname: 'Ґнfjа' },
      { id: 839, name: 'Анфуса', sname: 'Ґнfyса' },
      { id: 840, name: 'Анисия', sname: 'ҐнЂсіа' },
      { id: 841, name: 'Аполлинария', sname: 'Ґполлінaріа' },
      { id: 842, name: 'Апфия', sname: 'Ґпфjа' },
      { id: 843, name: 'Ариадна', sname: 'Ґріaдна' },
      { id: 844, name: 'Артемия', sname: 'Ґртeміа' },
      { id: 845, name: 'Архелая', sname: 'Ґрхелaа' },
      { id: 846, name: 'Аскитрея', sname: 'Ґскитрeа' },
      { id: 847, name: 'Асклиада', sname: 'Ґскліaда' },
      { id: 848, name: 'Асклипиодота', sname: 'Ґсклипіод0та' },
      { id: 849, name: 'Афанасия', sname: 'Ґfанaсіа' },
      { id: 850, name: 'Августа', sname: 'Ѓvгуста' },
      { id: 851, name: 'Валентина', sname: 'Валентjна' },
      { id: 852, name: 'Варвара', sname: 'Варвaра' },
      { id: 853, name: 'Василиса', sname: 'Васілjсса' },
      { id: 854, name: 'Васса', sname: 'Вaсса' },
      { id: 855, name: 'Вевея', sname: 'Вевeа' },
      { id: 856, name: 'Вероника', sname: 'Веронjка' },
      { id: 857, name: 'Виринея', sname: 'Вирінeа' },
      { id: 858, name: 'Вера', sname: 'Вёра' },
      { id: 859, name: 'Гаафа', sname: 'Гаafа' },
      { id: 860, name: 'Гаиания', sname: 'Гаіанjа' },
      { id: 861, name: 'Галина', sname: 'Гали1на' },
      { id: 862, name: 'Глафира', sname: 'ГлафЂра' },
      { id: 863, name: 'Гликерия', sname: 'Глmкeріа' },
      { id: 864, name: 'Голиндуха', sname: 'Голіндyха' },
      { id: 865, name: 'Дарья', sname: 'Дaріа' },
      { id: 866, name: 'Домна', sname: 'Д0мна' },
      { id: 867, name: 'Домника', sname: 'Домнjка' },
      { id: 868, name: 'Домнина', sname: 'Домнjна' },
      { id: 869, name: 'Дросида', sname: 'Дросjда' },
      { id: 870, name: 'Дуклида', sname: 'Дуклjда' },
      { id: 871, name: 'Дорофея', sname: 'Дwроfeа' },
      { id: 872, name: 'Екатерина', sname: 'Е#катерjна' },
      { id: 873, name: 'Елена', sname: 'Е#лeна' },
      { id: 874, name: 'Еликонида', sname: 'Е#ліконjда' },
      { id: 875, name: 'Елизавета', sname: 'Е#лісавeта' },
      { id: 876, name: 'Елисавефа', sname: 'Е#лісавefа' },
      { id: 877, name: 'Еннафа', sname: 'Е#ннafа' },
      { id: 878, name: 'Епистима', sname: 'Е#пісти1ма' },
      { id: 879, name: 'Епихария', sname: 'Е#піхaріа' },
      { id: 880, name: 'Ермиония', sname: 'Е#рміwнjа' },
      { id: 881, name: 'Еротиида', sname: 'Е#рwтіи1да' },
      { id: 882, name: 'Ева', sname: 'Е$vа' },
      { id: 883, name: 'Еванфия', sname: 'Е#vaнfіа' },
      { id: 884, name: 'Еввула', sname: 'Е#vвyла' },
      { id: 885, name: 'Евгения', sname: 'Е#vгeніа' },
      { id: 886, name: 'Евдокия', sname: 'Е#vдокjа' },
      { id: 887, name: 'Евдоксия', sname: 'Е#vдоxjа' },
      { id: 888, name: 'Евлалия', sname: 'Е#vлaліа' },
      { id: 889, name: 'Евлампия', sname: 'Е#vлампjа' },
      { id: 890, name: 'Евникия', sname: 'Е#vнjкіа' },
      { id: 891, name: 'Евпраксия', sname: 'Е#vпраxjа' },
      { id: 892, name: 'Евсевия', sname: 'Е#vсevіа' },
      { id: 893, name: 'Евстолия', sname: 'Е#vст0ліа' },
      { id: 894, name: 'Евтропия', sname: 'Е#vтр0піа' },
      { id: 895, name: 'Евфимия', sname: 'Е#vфи1міа' },
      { id: 896, name: 'Евфрасия', sname: 'Е#vфрасjа' },
      { id: 897, name: 'Евфросиния', sname: 'Е#vфросЂніа' },
      { id: 898, name: 'Евфалия', sname: 'Е#vfaліа' },
      { id: 899, name: 'Зинаида', sname: 'Зинаjда' },
      { id: 900, name: 'Зиновия', sname: 'Зиновjа' },
      { id: 901, name: 'Зоя', sname: 'ЗHа' },
      { id: 902, name: 'Ироида', sname: 'И#рwjда' },
      { id: 903, name: 'Ия', sname: 'Ја' },
      { id: 904, name: 'Илария', sname: 'Їлaріа' },
      { id: 905, name: 'Ираида', sname: 'Їраjда' },
      { id: 906, name: 'Ирина', sname: 'Їри1на' },
      { id: 907, name: 'Исидора', sname: 'ЇсідHра' },
      { id: 908, name: 'Юлия', sname: 'Їyліа' },
      { id: 909, name: 'Ульяна', sname: 'Їуліанjа' },
      { id: 910, name: 'Иулитта', sname: 'Їулjтта' },
      { id: 911, name: 'Иуния', sname: 'Їyніа' },
      { id: 912, name: 'Устинья', sname: 'Їустjна' },
      { id: 913, name: 'Иовилла', sname: 'Їwвjлла' },
      { id: 914, name: 'Каздоя', sname: 'Казд0а' },
      { id: 915, name: 'Валерия', sname: 'Калeріа' },
      { id: 916, name: 'Калиса', sname: 'Калjса' },
      { id: 917, name: 'Каллиста', sname: 'Каллjста' },
      { id: 918, name: 'Каллисфения', sname: 'Каллісfeніа' },
      { id: 919, name: 'Капитолина', sname: 'Капетwлjна' },
      { id: 920, name: 'Касиния', sname: 'Касjніа' },
      { id: 921, name: 'Керкира', sname: 'КеркЂра' },
      { id: 922, name: 'Кикилия', sname: 'Кікілjа' },
      { id: 923, name: 'Клавдия', sname: 'Клаvдjа' },
      { id: 924, name: 'Клеопатра', sname: 'Клеопaтра' },
      { id: 925, name: 'Конкордия', sname: 'Конк0рдіа' },
      { id: 926, name: 'Крискентия', sname: 'Крискeнтіа' },
      { id: 927, name: 'Кира', sname: 'КЂра' },
      { id: 928, name: 'Кириакия', sname: 'Кmріакjа' },
      { id: 929, name: 'Кириена', sname: 'Кmріeна' },
      { id: 930, name: 'Кирилла', sname: 'Кmрjлла' },
      { id: 931, name: 'Лариса', sname: 'Ларjса' },
      { id: 932, name: 'Леонилла', sname: 'Леонjлла' },
      { id: 933, name: 'Лукия', sname: 'Лукjа' },
      { id: 934, name: 'Лукина', sname: 'Лукjна' },
      { id: 935, name: 'Любовь', sname: 'Люб0вь' },
      { id: 936, name: 'Людмила', sname: 'Людми1ла' },
      { id: 937, name: 'Лидия', sname: 'Лmдjа' },
      { id: 938, name: 'Макрина', sname: 'Макрjна' },
      { id: 939, name: 'Мамелхфа', sname: 'Мамeлхfа' },
      { id: 940, name: 'Мамика', sname: 'МамЂка' },
      { id: 941, name: 'Манефа', sname: 'Манeфа' },
      { id: 942, name: 'Мария', sname: 'Марjа' },
      { id: 943, name: 'Мариамна', sname: 'Маріaмна' },
      { id: 944, name: 'Марина', sname: 'Марjна' },
      { id: 945, name: 'Марионилла', sname: 'Маріwнjлла' },
      { id: 946, name: 'Марфа', sname: 'Мaрfа' },
      { id: 947, name: 'Мастридия', sname: 'Мастри1діа' },
      { id: 948, name: 'Матрона', sname: 'МатрHна' },
      { id: 949, name: 'Мавра', sname: 'Мavра' },
      { id: 950, name: 'Мелания', sname: 'Мелaніа' },
      { id: 951, name: 'Мелитина', sname: 'Мелітjна' },
      { id: 952, name: 'Минодора', sname: 'МинодHра' },
      { id: 953, name: 'Митродора', sname: 'МитродHра' },
      { id: 954, name: 'Муза', sname: 'Мyза' },
      { id: 955, name: 'Миропия', sname: 'Мmропjа' },
      { id: 956, name: 'Надежда', sname: 'Надeжда' },
      { id: 957, name: 'Наталия', sname: 'Натaліа' },
      { id: 958, name: 'Неонилла', sname: 'Неонjла' },
      { id: 959, name: 'Ника', sname: 'Нjка' },
      { id: 960, name: 'Нина', sname: 'Нjна' },
      { id: 961, name: 'Нонна', sname: 'Н0нна' },
      { id: 962, name: 'Нунехия', sname: 'Нунехjа' },
      { id: 963, name: 'Нимфодора', sname: 'НmмфодHра' },
      { id: 964, name: 'Ольга', sname: 'Џльга' },
      { id: 965, name: 'Олимпиада', sname: 'Nлmмпіaда' },
      { id: 966, name: 'Параскева', sname: 'Параскevа' },
      { id: 967, name: 'Павла', sname: 'Пavла' },
      { id: 968, name: 'Пелагея', sname: 'Пелагjа' },
      { id: 969, name: 'Перпетуя', sname: 'Перпeтуа' },
      { id: 970, name: 'Пиама', sname: 'Піaма' },
      { id: 971, name: 'Платонида', sname: 'Платwнjда' },
      { id: 972, name: 'Поликсения', sname: 'Полmxeніа' },
      { id: 973, name: 'Поплия', sname: 'Поплjа' },
      { id: 974, name: 'Препедигна', sname: 'Препедjгна' },
      { id: 975, name: 'Прискилла', sname: 'Пріскjлла' },
      { id: 976, name: 'Просдока', sname: 'Просд0ка' },
      { id: 977, name: 'Проскудия', sname: 'Проскyдіа' },
      { id: 978, name: 'Пульхерия', sname: 'Пулхeріа' },
      { id: 979, name: 'Раиса', sname: 'Раи1са' },
      { id: 980, name: 'Рипсимия', sname: 'Ріpімjа' },
      { id: 981, name: 'Руфина', sname: 'Руфjна' },
      { id: 982, name: 'Севастиана', sname: 'Севастіaна' },
      { id: 983, name: 'Серафима', sname: 'Серафjма' },
      { id: 984, name: 'Сира', sname: 'Сjра' },
      { id: 985, name: 'Снандулия', sname: 'Снандyліа' },
      { id: 986, name: 'Соломония', sname: 'Соломwнjа' },
      { id: 987, name: 'София', sname: 'Софjа' },
      { id: 988, name: 'Стефанида', sname: 'Стефанjда' },
      { id: 989, name: 'Сосанна', sname: 'Сwсaнна' },
      { id: 990, name: 'Сосипатра', sname: 'Сwсіпaтра' },
      { id: 991, name: 'Синклитикия', sname: 'Сmгклитікjа' },
      { id: 992, name: 'Таисия', sname: 'Таjсіа' },
      { id: 993, name: 'Татиана', sname: 'Татіaна' },
      { id: 994, name: 'Текуса', sname: 'Текyса' },
      { id: 995, name: 'Трифена', sname: 'Трmфeна' },
      { id: 996, name: 'Валентина', sname: 'Ўалентjна' },
      { id: 997, name: 'Фаина', sname: 'Фаjна' },
      { id: 998, name: 'Фавства', sname: 'Фavста' },
      { id: 999, name: 'Феврония', sname: 'ФеврHніа' },
      { id: 1000, name: 'Фервуфа', sname: 'Фервyfа' },
      { id: 1001, name: 'Филицитата', sname: 'Филікітaта' },
      { id: 1002, name: 'Филицата', sname: 'Филіцaта' },
      { id: 1003, name: 'Фива', sname: 'Фjва' },
      { id: 1004, name: 'Филиппия', sname: 'Філіппjа' },
      { id: 1005, name: 'Филонилла', sname: 'Філонjлла' },
      { id: 1006, name: 'Фотина', sname: 'Фwтjна' },
      { id: 1007, name: 'Фотиния', sname: 'Фwтjніа' },
      { id: 1008, name: 'Хариесса', sname: 'Харіeсса' },
      { id: 1009, name: 'Харита', sname: 'Харjта' },
      { id: 1010, name: 'Харитина', sname: 'Харітjна' },
      { id: 1011, name: 'Хиония', sname: 'Хіонjа' },
      { id: 1012, name: 'Христина', sname: 'Хрістjна' },
      { id: 1013, name: 'Христодула', sname: 'Хрістодyла' },
      { id: 1014, name: 'Хрисия', sname: 'Хрmсjа' },
      { id: 1015, name: 'Ксанфиппа', sname: 'Xанfjппа' },
      { id: 1016, name: 'Ксения', sname: 'Xeніа' },
      { id: 1017, name: 'Фекла', sname: 'Feкла' },
      { id: 1018, name: 'Феодосия', sname: 'Fеод0сіа' },
      { id: 1019, name: 'Феодотия', sname: 'Fеод0тіа' },
      { id: 1020, name: 'Феодулия', sname: 'Fеодyліа' },
      { id: 1021, name: 'Феодора', sname: 'FеодHра' },
      { id: 1022, name: 'Феозва', sname: 'Fе0зва' },
      { id: 1023, name: 'Феоктиста', sname: 'Fеоктjста' },
      { id: 1024, name: 'Феонилла', sname: 'Fеонjлла' },
      { id: 1025, name: 'Феопистия', sname: 'Fеопістjа' },
      { id: 1026, name: 'Феофания', sname: 'Fеофaніа' },
      { id: 1027, name: 'Феофила', sname: 'Fеофjла' },
      { id: 1028, name: 'Фессалоникия', sname: 'Fессалонікjа' },
      { id: 1029, name: 'Фивея', sname: 'Fивeа' },
      { id: 1030, name: 'Фомаида', sname: 'Fwмаjда' }
    ]
  },
  getters: {
    personsArr: (state) => Object.values(state.persons)
  }
}

export default personsStore
