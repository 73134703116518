const titStore = {
  namespaced: true,
  state: {
    bykvitsi: [
      {
        id: 1,
        irmol: 'а4зъ',
        snd: '[а]',
        bykva: 'а',
        forBtns: ['а', 'з', 'z']
      },
      {
        id: 2,
        irmol: 'бyки',
        snd: '[б]',
        bykva: 'б',
        forBtns: ['б', 'у', 'к']
      },
      {
        id: 3,
        irmol: 'вёди',
        snd: '[в]',
        bykva: 'в',
        forBtns: ['в', 'е', 'д']
      },
      {
        id: 4,
        irmol: 'глаг0ль',
        snd: '[г]',
        bykva: 'г',
        forBtns: ['г', 'л', 'а']
      },
      {
        id: 5,
        irmol: 'добро2',
        snd: '[д]',
        bykva: 'д',
        forBtns: ['д', 'о', 'б']
      },
      {
        id: 6,
        irmol: 'є4сть',
        snd: '[е]',
        bykva: 'є, е',
        forBtns: ['є', 'с', 'т']
      },
      {
        id: 7,
        irmol: 'живёте',
        snd: '[ж]',
        bykva: 'ж',
        forBtns: ['ж', 'в', 'э']
      },
      {
        id: 8,
        irmol: 'ѕэлw2',
        snd: '[з]',
        bykva: 'ѕ',
        forBtns: ['ѕ', 'э', 'w']
      },
      {
        id: 9,
        irmol: 'землS',
        snd: '[з]',
        bykva: 'з',
        forBtns: ['з', 'л', 'м']
      },
      {
        id: 10,
        irmol: 'и4же',
        snd: '[и]',
        bykva: 'и',
        forBtns: ['и', ' і ', 'н']
      },
      {
        id: 11,
        irmol: 'и5',
        snd: '[и]',
        bykva: ' і ',
        forBtns: [' і ', 'и', 'н']
      },
      {
        id: 12,
        irmol: 'кaкw',
        snd: '[к]',
        bykva: 'к',
        forBtns: ['к', 'w', 'а']
      },
      {
        id: 13,
        irmol: 'лю1ди',
        snd: '[л]',
        bykva: 'л',
        forBtns: ['л', 'ю', 'д']
      },
      {
        id: 14,
        irmol: 'мыслёте',
        snd: '[м]',
        bykva: 'м',
        forBtns: ['м', 'ы', 'л']
      },
      {
        id: 15,
        irmol: 'нaшъ',
        snd: '[н]',
        bykva: 'н',
        forBtns: ['н', 'а', 'ш']
      },
      {
        id: 16,
        irmol: 'o4нъ',
        snd: '[о]',
        bykva: 'o, о',
        forBtns: ['o', 'н', 'и']
      },
      {
        id: 17,
        irmol: 'пок0й',
        snd: '[п]',
        bykva: 'п',
        forBtns: ['п', 'о', 'й']
      },
      {
        id: 18,
        irmol: 'рцы2',
        snd: '[р]',
        bykva: 'р',
        forBtns: ['р', 'ц', 'ы']
      },
      {
        id: 19,
        irmol: 'сл0во',
        snd: '[с]',
        bykva: 'с',
        forBtns: ['с', 'л', 'о']
      },
      {
        id: 20,
        irmol: 'твeрдо',
        snd: '[т]',
        bykva: 'т',
        forBtns: ['т', 'в', 'д']
      },
      {
        id: 21,
        irmol: 'ќкъ',
        snd: '[у]',
        bykva: 'u, у',
        forBtns: ['u', 'к', 'ъ']
      },
      {
        id: 22,
        irmol: 'фeртъ',
        snd: '[ф]',
        bykva: 'ф',
        forBtns: ['ф', 'э', 'р']
      },
      {
        id: 23,
        irmol: 'хёръ',
        snd: '[х]',
        bykva: 'х',
        forBtns: ['х', 'е', 'р']
      },
      {
        id: 24,
        irmol: 'w4тъ',
        snd: '[от]',
        bykva: 't',
        forBtns: ['t', 'т', 'о']
      },
      {
        id: 25,
        irmol: 'цы2',
        snd: '[ц]',
        bykva: 'ц',
        forBtns: ['ц', 'ы', 'и']
      },
      {
        id: 26,
        irmol: 'чeрвь',
        snd: '[ч]',
        bykva: 'ч',
        forBtns: ['ч', 'р', 'в']
      },
      { id: 27, irmol: 'шA', snd: '[ш]', bykva: 'ш', forBtns: ['ш', 'а', 'щ'] },
      { id: 28, irmol: 'щA', snd: '[щ]', bykva: 'щ', forBtns: ['щ', 'ш', 'я'] },
      { id: 29, irmol: 'є4ръ', snd: '', bykva: 'ъ', forBtns: ['ъ', 'р', 'ь'] },
      {
        id: 30,
        irmol: 'є3ры2',
        snd: '[ы]',
        bykva: 'ы',
        forBtns: ['ы', 'р', 'ъ']
      },
      { id: 31, irmol: 'є4рь', snd: '', bykva: 'ь', forBtns: ['ь', 'р', 'э'] },
      {
        id: 32,
        irmol: 'я4ть',
        snd: '[е]',
        bykva: 'э',
        forBtns: ['э', 'z', 'т']
      },
      { id: 33, irmol: 'ю5', snd: '[ю]', bykva: 'ю', forBtns: ['ю', 'у', 'й'] },
      { id: 34, irmol: 'я5', snd: '[я]', bykva: 'я', forBtns: ['я', 'z', 'й'] },
      {
        id: 35,
        irmol: 'w3мeга',
        snd: '[о]',
        bykva: 'w, q',
        forBtns: ['w', 'о', 'м']
      },
      {
        id: 36,
        irmol: 'ю4съ мaлый',
        snd: '[я]',
        bykva: 'z',
        forBtns: ['z', 'м', 'а']
      },
      {
        id: 37,
        irmol: 'кси2',
        snd: '[кс]',
        bykva: 'x',
        forBtns: ['x', 'к', 'с']
      },
      {
        id: 38,
        irmol: 'пси2',
        snd: '[пс]',
        bykva: 'p',
        forBtns: ['p', 'п', 'с']
      },
      {
        id: 39,
        irmol: 'fитA',
        snd: '[ф]',
        bykva: 'f',
        forBtns: ['f', 'т', 'и']
      },
      {
        id: 40,
        irmol: 'и4жица',
        snd: '[и]',
        bykva: 'm',
        forBtns: ['m', 'и', 'ц']
      },
      {
        id: 41,
        irmol: 'и4жица',
        snd: '[в]',
        bykva: 'v',
        forBtns: ['v', 'в', 'ц']
      }
    ],
    phrases: [
      {
        id: 1,
        msec: 'нб7сA',
        first: 'взhде на',
        prompt: 'небесA',
        pairs: ['е', 'э']
      },
      { id: 2, mfirst: 'џч7е', sec: 'нaшъ', prompt: 'џтче', pairs: ['т', 't'] },
      { id: 3, mfirst: 'бGъ', sec: 'є4сть', prompt: 'б0гъ', pairs: ['0', 'о'] },
      {
        id: 4,
        mfirst: 'ѓгGлъ',
        sec: 'храни1тель',
        prompt: 'ѓнгелъ',
        pairs: ['н', 'г']
      },
      {
        id: 5,
        msec: 'б9е',
        first: 'поми1луй мS',
        prompt: 'б0же',
        pairs: ['0', 'о']
      },
      {
        id: 6,
        msec: 'бGор0дица',
        first: 'прест7az',
        prompt: 'богор0дица',
        pairs: ['о', 'w']
      },
      {
        id: 7,
        mfirst: 'бlжeнъ',
        sec: 'мyжъ',
        prompt: 'блажeнъ',
        pairs: ['а', 'о']
      },
      {
        id: 8,
        mfirst: 'бlгословeнъ',
        sec: 'бGъ',
        prompt: 'благословeнъ',
        pairs: ['а', 'о']
      },
      {
        id: 9,
        mfirst: 'гlати',
        sec: 'си6лы тво‰',
        prompt: 'глаг0лати',
        pairs: ['а', '0']
      },
      {
        id: 10,
        mfirst: 'д¦ъ',
        sec: 'ст7hй',
        prompt: 'дyхъ',
        pairs: ['y', 'u1']
      },
      {
        id: 11,
        msec: 'дш7A',
        first: 'живaz',
        prompt: 'душA',
        pairs: ['у', 'u']
      },
      {
        id: 12,
        mfirst: 'дв7а',
        sec: 'бGор0дица',
        prompt: 'дёва',
        pairs: ['ё', 'e']
      },
      {
        id: 13,
        mfirst: 'дв7и1ца',
        sec: 'чcтаz',
        prompt: 'дэви1ца',
        pairs: ['э', 'е']
      },
      {
        id: 14,
        msec: 'дн7ь',
        first: 'д0брый',
        prompt: 'дeнь',
        pairs: ['e', 'ё']
      },
      {
        id: 15,
        mfirst: 'дн7сь',
        sec: 'сп7сeніе',
        prompt: 'днeсь',
        pairs: ['e', 'ё']
      },
      {
        id: 16,
        msec: 'ї}съ',
        first: 'речE',
        prompt: 'їисyсъ',
        pairs: ['с', 'y']
      },
      {
        id: 17,
        msec: 'ї}ль',
        first: 'ст7hй',
        prompt: 'їзрaиль',
        pairs: ['з', 'р']
      },
      {
        id: 18,
        mfirst: 'кRщeніе',
        sec: 'їwaнново',
        prompt: 'крещeніе',
        pairs: ['е', 'э']
      },
      {
        id: 19,
        msec: 'м™и',
        first: 'слaвнаz',
        prompt: 'мaти',
        pairs: ['a', 'а']
      },
      {
        id: 20,
        mfirst: 'м™рь',
        sec: 'б9іz',
        prompt: 'мaтерь',
        pairs: ['a', 'е']
      },
      {
        id: 21,
        msec: 'мRjа',
        first: 'бGородица',
        prompt: 'марjа',
        pairs: ['а', 'я']
      },
      {
        id: 22,
        mfirst: 'м§никъ',
        sec: 'Їwaннъ в0инъ',
        prompt: 'мyченикъ',
        pairs: ['y', 'е']
      },
      {
        id: 23,
        mfirst: 'нб7о',
        sec: 'tвeрсто',
        prompt: 'нeбо',
        pairs: ['e', 'ё']
      },
      {
        id: 24,
        msec: 'нн7э',
        first: 'слaва и',
        prompt: 'нhнэ',
        pairs: ['h', 'ы']
      },
      {
        id: 25,
        msec: 'nц7ъ',
        first: 'млcтивый',
        prompt: 'nтeцъ',
        pairs: ['т', 'e']
      },
      {
        id: 26,
        msec: 'n§ество',
        first: 'нб7сное',
        prompt: 'nтeчество',
        pairs: ['т', 'e']
      },
      {
        id: 27,
        mfirst: 'прес™hй',
        sec: 'грaдъ',
        prompt: 'пресвzтhй',
        pairs: ['в', 'z']
      },
      {
        id: 28,
        mfirst: 'с™ъ',
        sec: 'бGъ нaшъ',
        prompt: 'свsтъ',
        pairs: ['в', 's']
      },
      {
        id: 29,
        mfirst: 'сlнце',
        sec: 'прaвды',
        prompt: 'с0лнце',
        pairs: ['0', 'H']
      },
      {
        id: 30,
        mfirst: 'сп7съ',
        sec: 'нерукотв0рный',
        prompt: 'спaсъ',
        pairs: ['a', 'а']
      },
      {
        id: 31,
        mfirst: 'сн7ъ',
        sec: 'б9ій',
        prompt: 'сhнъ',
        pairs: ['h', 'ы']
      },
      {
        id: 32,
        msec: 'трис™0е',
        first: 'моли1тва:',
        prompt: 'трисвzт0е',
        pairs: ['в', 'z']
      },
      {
        id: 33,
        mfirst: 'ў§тль',
        sec: 'бlгjй',
        prompt: 'ўчи1тель',
        pairs: ['и1', 'е']
      },
      {
        id: 34,
        msec: 'ўчн7къ',
        first: 'вёрный',
        prompt: 'ўчени1къ',
        pairs: ['е', 'и1']
      },
      {
        id: 35,
        mfirst: 'цRь',
        sec: 'вэкHвъ',
        prompt: 'цaрь',
        pairs: ['a', 'а']
      },
      {
        id: 36,
        mfirst: 'цRковь',
        sec: 'б9іz',
        prompt: 'цeрковь',
        pairs: ['e', 'ё']
      },
      {
        id: 37,
        msec: 'чlвёкъ',
        first: 'сE,',
        prompt: 'человёкъ',
        pairs: ['е', 'э']
      },
      {
        id: 38,
        mfirst: 'бцdа',
        sec: 'несквeрнаz',
        prompt: 'богор0дица',
        pairs: ['о', 'р']
      },
      {
        id: 39,
        mfirst: 'блгdть',
        sec: 'наполнsющаz',
        prompt: 'благодaть',
        pairs: ['а', 'a']
      },
      {
        id: 40,
        mfirst: 'бlгодaть',
        sec: 'наполнsющаz',
        prompt: 'благодaть',
        pairs: ['а', 'о']
      },
      {
        id: 41,
        mfirst: 'вLчца',
        sec: 'мjра',
        prompt: 'владhчица',
        pairs: ['а', 'h']
      },
      {
        id: 42,
        mfirst: 'вLка',
        sec: 'царeй земнhхъ',
        prompt: 'владhка',
        pairs: ['а', 'h']
      },
      {
        id: 43,
        mfirst: 'вLчество',
        sec: 'твоE',
        prompt: 'владhчество',
        pairs: ['а', 'h']
      },
      {
        id: 44,
        mfirst: 'мрdъ',
        sec: 'w3 хrтЁ',
        prompt: 'мyдръ',
        pairs: ['y', 'u1']
      },
      {
        id: 45,
        mfirst: 'мрdость',
        sec: 'и3 прaвда',
        prompt: 'мyдрость',
        pairs: ['y', 'u1']
      },
      {
        id: 46,
        msec: 'мLнцъ',
        first: 'спsщій',
        prompt: 'младeнецъ',
        pairs: ['а', 'e']
      },
      {
        id: 47,
        mfirst: 'нLz',
        sec: 'пaсхи',
        prompt: 'недёлz',
        pairs: ['е', 'ё']
      },
      {
        id: 48,
        msec: 'поd',
        first: 'песнопёніе:',
        prompt: 'под0бенъ',
        pairs: ['0', 'б']
      },
      {
        id: 49,
        mfirst: 'премdрость',
        sec: 'пр0сти',
        prompt: 'премyдрость',
        pairs: ['y', 'u1']
      },
      {
        id: 50,
        mfirst: 'првdникъ',
        sec: 'возвесели1тсz',
        prompt: 'прaведникъ',
        pairs: ['a', 'е']
      },
      {
        id: 51,
        msec: 'срdце',
        first: 'д0брое',
        prompt: 'сeрдце',
        pairs: ['e', 'ё']
      },
      {
        id: 52,
        mfirst: 'є3vgліе',
        sec: 't мaрка',
        prompt: 'є3vaнгеліе',
        pairs: ['a', 'н']
      },
      {
        id: 53,
        mfirst: 'є3vgлjстъ',
        sec: 'ЛукA',
        prompt: 'є3vангелjстъ',
        pairs: ['н', 'е']
      },
      {
        id: 54,
        mfirst: 'прbркъ',
        sec: 'Їсaіz',
        prompt: 'прор0къ',
        pairs: ['0', 'о']
      },
      {
        id: 55,
        msec: 'трbцэ',
        first: 'величaніе',
        prompt: 'тр0ицэ',
        pairs: ['и', 'і']
      },
      {
        id: 56,
        msec: 'трbчны',
        first: 'тропари2',
        prompt: 'тр0ичны',
        pairs: ['и', 'ч']
      },
      {
        id: 57,
        msec: 'и3м>къ',
        first: '†',
        prompt: 'и3мzрёкъ',
        pairs: ['z', 'р']
      },
      {
        id: 58,
        mfirst: 'ґпcлъ',
        sec: 'Їwaннъ',
        prompt: 'ґп0столъ',
        pairs: ['0', 'о']
      },
      {
        id: 59,
        mfirst: 'ґрхіепcкпъ',
        sec: 'Гeрманъ',
        prompt: 'ґрхіепи1скопъ',
        pairs: ['и1', 'о']
      },
      {
        id: 60,
        mfirst: 'ґрхaгGлъ',
        sec: 'Гавріи1лъ',
        prompt: 'ґрхaнгелъ',
        pairs: ['н', 'е']
      },
      {
        id: 61,
        msec: 'бжcтвA',
        first: 'блистaніе',
        prompt: 'божествA',
        pairs: ['о', 'е']
      },
      {
        id: 62,
        mfirst: 'бlгочcтнw',
        sec: 'жи1ти',
        prompt: 'благочeстнw',
        pairs: ['а', 'e']
      },
      {
        id: 63,
        msec: 'воскrніе',
        first: 'свётлое',
        prompt: 'воскресeніе',
        pairs: ['е', 'e']
      },
      {
        id: 64,
        mfirst: 'гDь',
        sec: 'и3 бGъ нaшъ',
        prompt: 'госп0дь',
        pairs: ['о', '0']
      },
      {
        id: 65,
        mfirst: 'гDи',
        sec: 'поми1луй',
        prompt: 'г0споди',
        pairs: ['0', 'о']
      },
      {
        id: 66,
        msec: 'гDнь',
        first: 'вх0дъ',
        prompt: 'госп0день',
        pairs: ['о', 'п']
      },
      {
        id: 67,
        mfirst: 'гDство',
        sec: 'некрад0мое',
        prompt: 'госп0дство',
        pairs: ['п', '0']
      },
      {
        id: 68,
        mfirst: 'двcтво',
        sec: 'сохрани1ла є3си2',
        prompt: 'дёвство',
        pairs: ['ё', 'e']
      },
      {
        id: 69,
        mfirst: 'є3пcкпъ',
        sec: 'Fеофaнъ',
        prompt: 'є3пи1скопъ',
        pairs: ['и1', 'о']
      },
      {
        id: 70,
        mfirst: 'їеrли1мъ',
        sec: 'ст7hй',
        prompt: 'їерусали1мъ',
        pairs: ['у', 'а']
      },
      {
        id: 71,
        mfirst: 'кrтъ',
        sec: 'животворsщій',
        prompt: 'крeстъ',
        pairs: ['e', 'ё']
      },
      {
        id: 72,
        msec: 'млcсть',
        first: 'вёліz',
        prompt: 'ми1лость',
        pairs: ['и1', 'о']
      },
      {
        id: 73,
        mfirst: 'млcрдіе',
        sec: 'неизречeнное',
        prompt: 'милосeрдіе',
        pairs: ['и', 'e']
      },
      {
        id: 74,
        mfirst: 'мцcъ',
        sec: 'и3 лёто',
        prompt: 'мёсzцъ',
        pairs: ['ё', 'e']
      },
      {
        id: 75,
        msec: 'нбcный',
        first: 'хрaмъ',
        prompt: 'небeсный',
        pairs: ['е', 'e']
      },
      {
        id: 76,
        msec: 'пrнw',
        first: 'нhнэ и3',
        prompt: 'при1снw',
        pairs: ['и1', 'j']
      },
      {
        id: 77,
        msec: 'ржcтво2',
        first: 'чyдное',
        prompt: 'рождество2',
        pairs: ['о', 'д']
      },
      {
        id: 78,
        mfirst: 'стrть',
        sec: 'хrт0ва',
        prompt: 'стрaсть',
        pairs: ['a', 'а']
      },
      {
        id: 79,
        mfirst: 'хrт0съ',
        sec: 'гDь',
        prompt: 'христ0съ',
        pairs: ['и', 'і']
      },
      {
        id: 80,
        msec: 'хrтE',
        first: 'ї}се',
        prompt: 'христE',
        pairs: ['и', 'і']
      },
      {
        id: 81,
        msec: 'хrтіaнинъ',
        first: 'мyдрый',
        prompt: 'христіaнинъ',
        pairs: ['и', 'і']
      },
      {
        id: 82,
        mfirst: 'цrкій',
        sec: 'черт0гъ',
        prompt: 'цaрскій',
        pairs: ['a', 'а']
      },
      {
        id: 83,
        mfirst: 'чтcь',
        sec: 'и3 си1ла',
        prompt: 'чeсть',
        pairs: ['e', 'е']
      },
      {
        id: 84,
        mfirst: 'чтcенъ',
        sec: 'мyжъ',
        prompt: 'чeстенъ',
        pairs: ['e', 'е']
      },
      {
        id: 85,
        msec: 'чтcнhй',
        first: 'п0zсъ',
        prompt: 'честнhй',
        pairs: ['е', 'e']
      },
      {
        id: 86,
        mfirst: 'чтcый',
        sec: 'четверт0къ',
        prompt: 'чи1стый',
        pairs: ['и1', 'и']
      },
      {
        id: 87,
        msec: 'бlговёщеніz',
        first: 'прaздникъ',
        prompt: 'благовёщеніz',
        pairs: ['а', 'о']
      },
      {
        id: 88,
        mfirst: 'блгcвeніе',
        sec: 'свhше',
        prompt: 'благословeніе',
        pairs: ['а', 'о']
      },
      {
        id: 89,
        msec: 'бGомdръ',
        first: 'настaвникъ',
        prompt: 'богомyдръ',
        pairs: ['о', 'y']
      },
      {
        id: 90,
        msec: 'бGомaти',
        first: 'Q,',
        prompt: 'богомaти',
        pairs: ['о', 'o']
      },
      {
        id: 91,
        mfirst: 'б9ій',
        sec: 'слугA',
        prompt: 'б0жій',
        pairs: ['0', 'о']
      },
      {
        id: 92,
        mfirst: 'вlком§никъ',
        sec: 'Пантелеи1мwнъ',
        prompt: 'великомyченикъ',
        pairs: ['е', 'y']
      },
      {
        id: 93,
        msec: 'воцRи1сz',
        first: 'гDь',
        prompt: 'воцари1сz',
        pairs: ['а', 'я']
      },
      {
        id: 94,
        msec: 'в§лвёчитисz',
        first: 'бlговоли1вшій',
        prompt: 'вочеловёчитисz',
        pairs: ['о', 'е']
      },
      {
        id: 95,
        msec: 'гlъ',
        first: 'неизречeнный',
        prompt: 'глаг0лъ',
        pairs: ['а', '0']
      },
      {
        id: 96,
        mfirst: 'гпcжE',
        sec: 'бGороди1тельнице',
        prompt: 'госпожE',
        pairs: ['о', 'o']
      },
      {
        id: 97,
        msec: 'дв7дъ',
        first: 'цaрь',
        prompt: 'дави1дъ',
        pairs: ['а', 'и1']
      },
      {
        id: 98,
        mfirst: 'д0блcтвенный',
        sec: 'в0инъ',
        prompt: 'д0блественный',
        pairs: ['е', 'э']
      },
      {
        id: 99,
        msec: 'є3леwсщ7eніе',
        first: 'соб0рованіе, или',
        prompt: 'є3леwсвzщeніе',
        pairs: ['в', 'z']
      },
      {
        id: 100,
        mfirst: 'за?',
        sec: 'пeрвое',
        prompt: 'зачaло',
        pairs: ['a', 'л']
      },
      {
        id: 101,
        msec: 'кrтль',
        first: 'Їwaннъ',
        prompt: 'крести1тель',
        pairs: ['е', 'и1']
      },
      {
        id: 102,
        msec: 'кrти1тель',
        first: 'Їwaннъ',
        prompt: 'крести1тель',
        pairs: ['е', 'э']
      },
      {
        id: 103,
        mfirst: 'мlтва',
        sec: 'келeйнаz',
        prompt: 'моли1тва',
        pairs: ['о', 'и1']
      },
      {
        id: 104,
        msec: 'прdтeча',
        first: 'Їwaннъ',
        prompt: 'предтeча',
        pairs: ['е', 'э']
      },
      {
        id: 105,
        mfirst: 'прпdбный',
        sec: 'nтeцъ',
        prompt: 'препод0бный',
        pairs: ['е', '0']
      },
      {
        id: 106,
        mfirst: 'пrт0лъ',
        sec: 'nгнезрaчный',
        prompt: 'прест0лъ',
        pairs: ['е', 'э']
      },
      {
        id: 107,
        mfirst: 'м§нца',
        sec: 'Татіaна',
        prompt: 'мyченица',
        pairs: ['y', 'е']
      },
      {
        id: 108,
        mfirst: 'с™ль',
        sec: 'Ніколaй',
        prompt: 'свzти1тель',
        pairs: ['z', 'е']
      },
      {
        id: 109,
        mfirst: 'сп7сeніе',
        sec: 'мjру бhсть',
        prompt: 'спасeніе',
        pairs: ['а', 'о']
      },
      {
        id: 110,
        msec: 'с<',
        first: 'причaстный',
        prompt: 'сти1хъ',
        pairs: ['и1', 'х']
      },
      {
        id: 111,
        msec: 'ўбlжaти',
        first: 'хвали1ти и3',
        prompt: 'ўблажaти',
        pairs: ['а', 'о']
      },
      {
        id: 112,
        mfirst: 'чlвёчество',
        sec: 'хrтA',
        prompt: 'человёчество',
        pairs: ['е', 'о']
      }
    ]
  },
  getters: {
    bykvitsiArr: (state) => Object.values(state.bykvitsi),
    phrasesArr: (state) => Object.values(state.phrases)
  }
}

export default titStore
